.editMailStyle {
  height: 1rem;
  width: 1rem;
  margin-left: 0.7rem;
  margin: 0rem 0.3rem 0.3rem 0.5rem;
}

.useKeyStyle {
  padding: 1px 4px;
  background: #3a4a5e;
  border-radius: 10px;
  opacity: 0.8;
  margin: 2px;
  text-align: center;
}

.emailModel {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
  margin-top: 0px !important;
}
.mailLoder {
  display: flex;
  justify-content: center;
}
.user_inner_partts.email_partts {
  padding-bottom: 250px;
}
.email-button-container {
  display: flex;
  padding: 1rem;
}
.edit_icon_design {
  padding: 10px;
  margin-left: 12px;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 30%);
  border-radius: 5px;
  font-weight: bold;
}
.edit_icon_design:hover {
  box-shadow: 0px 0px 15px rgb(0 0 0 / 50%);
  background-color: #01c1c1;
  transition: all 0.1s ease-in-out;
  border-radius: 5px;
}
.upload_submit {
  background-color: #455060 !important;
}

.ck-content {
  color: black; /* Set default text color to black */
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
.email-button {
  background: #455060;
  margin-left: 0px !important;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  padding: 0 40px;
  border: 0px;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  display: inline-block;
}
.mail_container {
  position: relative;
  display: flex;
  flex-direction: column;
  /* width: 600px; */
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: #5b687a;
  color: white;
  outline: 0;
}
.modal-header .btn-close {
  color: white;
  background: url("../../assets//images/cross.png") no-repeat !important;
  opacity: 1;
}
.mail_template_container {
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: #5b687a;
  color: white;
  outline: 0;
  border-radius: 0.5rem;
}

.addnew_mail_list .new_mail {
  background: #01c1c1;
  border-radius: 30px;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  border: 0px;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  display: inline-block;
}
.email-button {
  background: #455060;
  /* margin-left: 1rem !important; */
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  border: 0px;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  display: inline-block;
}
.test-button {
  margin-left: 1rem !important;
}
/* .test-email-ok-button{
    background: #6c757d;
    margin-left: 1rem;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    padding: 0 40px;
    border: 0px;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    display: inline-block;
} */
.test-email-ok-button:hover {
  background: #283342;
}

/* Style email template model */

.content_modal .modal-content {
  background: #283342;
  color: #fff;
}
.content_modal .modal-header .btn-close {
  background: url(../../assets/images/cross.png) no-repeat;
  opacity: 1;
}
.content_modal .modal-content .modal-title {
  text-align: center;
  width: 100%;
}

.content_modal .upload_submit {
  background: #455060;
  border-radius: 30px;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  border: 0px;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  display: block;
  cursor: pointer;
  width: 100%;
}

/*  */

.email-button :hover {
  background: red;
}
.ifOHjV {
  padding-bottom: 60px !important;
}

.user_partts {
  /* background: #E5E5E5; */
  display: flex;
  justify-content: space-between;
}

.right_panel {
  max-width: 80%;
  width: 100%;
  flex: 0 0 auto;
}

.user_inner_partts {
  padding: 70px 60px 35px 60px;
}
.total_users_report {
  display: flex;
  justify-content: space-between;
}

.total_users_left {
  max-width: 70%;
  width: 100%;
}

.total_users_right {
  max-width: 30%;
  width: 100%;
}
.download_report {
  display: flex;
  justify-content: flex-end;
}
.total_users_left h2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
}

.total_and_new_user {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.t_userers_loading {
  /* max-width: 48%; */
  width: 100%;
}

.t_userers {
  padding: 30px 23px;
  background: #75859d;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.tusers_heading h2 {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.tusers_heading h4 {
  font-weight: 400;
  font-size: 34px;
  line-height: 42px;
  color: #ffffff;
  margin-bottom: 0px;
}

.tusers_heading h4 sub {
  font-size: 11px;
  color: #fff;
}

.drop_partts121 button {
  background: #75859d;
  border: 0.4px solid #1d242e;
  border-radius: 4px;
  width: 180px;
  height: 40px;
  font-weight: 400;
  font-size: 15px;
  color: #ffffff;
  padding: 0 10px;
}
.drop_partts121 button:after {
  position: absolute;
  content: "";
  border: none;
  background: url(../../assets/images/drop_down.png) no-repeat;
  width: 11px;
  background-position: 98% center;
  height: 6px;
  margin-left: 14px;
  margin-top: 9px;
}

.user_management_list {
  padding: 26px 60px;
  background: #384659;
}

.manage_heading_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manage_heading_search h2 {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.manage_texfieldt {
  width: 15rem !important;
  height: 26px;
  position: relative;
}

.manage_search_boxxx,
.manage_search_boxxx:focus {
  width: 100%;
  height: 100%;
  background: #2c3949;
  border-radius: 26px;
  border: 0px;
  padding: 0 30px;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
  outline: 0px;
  box-shadow: none;
}

.manage_search_boxxx::placeholder {
  font-size: 12px;
  line-height: 15px;
  color: #556578;
}

.search_imgg_manage {
  position: absolute;
  left: 10px;
  top: 0px;
}

.manage_table {
  background: #495a71;
  border-radius: 10px;
  /* overflow: hidden; */
  margin-top: 20px;
}
.manage_table table {
  margin-bottom: 0px;
}
.manage_table .table td,
.manage_table .table th,
.manage_table .table thead th {
  border-top: 0px;
  border-bottom: 1px solid #6d7d94;
  vertical-align: middle;
}

.manage_table .table th {
  padding: 10px 10px;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #ffffff;
}

.management_list_name {
  display: flex;
  align-items: center;
}

.man_user {
  width: 48px;
  height: 48px;
  border-radius: 100%;
}

.man_user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.man_username {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  margin-left: 10px;
  width: 100%;
  margin-left: 0px;
}

.man_email a,
.man_email,
.man_ph a,
.man_ph {
  font-weight: 400;
  font-size: 10px;
  /* line-height: 5px; */
  color: rgba(255, 255, 255, 0.5);
}

.three_dotss .drop_partts button:after {
  content: none;
}

.three_dotss .drop_partts .dropdown-menu {
  width: 80px;
  min-width: 80px;
  padding: 10px;
  background: #637896;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.three_dotss .drop_partts .dropdown-menu a {
  padding: 0px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.three_dotss .drop_partts .dropdown-menu a img {
  margin-right: 12px;
}

.three_dotss .drop_partts .dropdown-menu .dropdown-item:focus,
.three_dotss .drop_partts .dropdown-menu .dropdown-item:hover,
.three_dotss .drop_partts .dropdown-menu .dropdown-item.active,
.dropdown-item:active {
  background: transparent;
  color: #fff;
}

.dognut_partts {
  background: #495a71;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  position: relative;
}

.data_count {
  position: absolute;
  font-weight: 400;
  font-size: 40px;
  text-align: center;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
}
/* Pagination start */

.pagination_ul {
  margin-top: 30px;
}
.pagination_ul ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.pagination_ul ul li {
  list-style: none;
  margin: 0 5px;
}
.pagination_ul ul li a {
  background: #495a71;
  padding: 2px 7px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-size: 15px;
  display: inline-block;
}

.pagination_ul ul li a:hover {
  text-decoration: none;
}
.pagination_ul ul li.active {
  color: #fff;
  font-size: 15px;
}
.man_user img {
  border-radius: 100%;
}
/* Pagination End */

.rdt_Table {
  background-color: transparent !important;
}

.rdt_TableHeadRow {
  background-color: transparent !important;
}
.rdt_TableCell div:first-child {
  overflow: visible !important;
}
.rdt_TableCell div:first-child {
  overflow: visible !important;
  white-space: normal !important;
}

.manage_table ::-webkit-scrollbar {
  width: 15px;
}
.manage_table ::-o-scrollbar {
  width: 15px;
}
.manage_table ::-moz-scrollbar {
  width: 15px;
}
.manage_table ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.12);
  height: 12px;
}
.manage_table ::-o-scrollbar-track {
  background: rgba(0, 0, 0, 0.12);
  height: 12px;
}
.manage_table ::-moz-scrollbar-track {
  background: rgba(0, 0, 0, 0.12);
  height: 12px;
}
.manage_table ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.16);
  border-radius: 25px;
  height: 12px;
  width: 12px;
}
.manage_table ::-o-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.16);
  border-radius: 25px;
  height: 12px;
  width: 12px;
}
.manage_table ::-moz-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.16);
  border-radius: 25px;
  height: 12px;
  width: 12px;
}
.rdt_Pagination {
  background-color: #283342 !important;
  color: #fff !important;
}

.rdt_Pagination svg {
  fill: #fff !important;
}

.manage_table {
  position: relative;
}
.loader_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgba(0,0,0,0.6); */
  z-index: 9;
}
.ck-editor {
  background-color: #4d596a !important;
  border-radius: 6px;
}
/* .setting_right_partts_inner{
    padding: 10px 20px !important;
} */
.synccc_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: -75px;
  right: 0;
  bottom: -61px;
}
.pulse_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: -75px;
  right: 0;
  bottom: -61px;
}

.ifOHjV {
  padding-bottom: 60px !important;
}
@media screen and (max-width: 1199px) {
  .total_and_new_user {
    display: block;
  }
  .t_userers {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .manage_table table {
    width: 160%;
  }
  .data_count {
    font-size: 20px;
    top: 64%;
  }
}

@media screen and (max-width: 991px) {
  .data_count {
    font-size: 40px;
    top: 52%;
  }
  .total_users_report {
    display: block;
  }
  .total_users_left,
  .total_users_right {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .user_partts {
    display: block;
  }
  .right_panel {
    max-width: 100%;
    margin-top: 30px;
  }
}
._loading_overlay_overlay {
  background: rgba(0, 0, 0, 0.5);
}
.user_inner_partts.email_partts {
  padding-bottom: 250px;
}
