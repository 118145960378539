.man_user.story_user img {
  max-width: 50px;
  max-height: 50px;
  border-radius: 50px;
  object-fit: cover;
}
.man_user.story_user {
  /* width: 64px; */
  height: 64px;
  margin-right: 12px;
}
.title-_description span{
  font-size: 16px !important;
}
/* .man_text .story_text{
  width: 0px !important;
} */

.fixMargin{
  margin-left: 0px !important;
  cursor: pointer;
}

.man_text.story_text {
  width: 70% !important;
  left: -10px !important;
}
.manage_table_1 .rdt_TableCol > div > div[data-column-id="1"]{
  padding-left: 3.5rem;
}
.t_userers:hover{
  box-shadow: 0px 0px 15px rgb(0 0 0 / 40%);
  background: linear-gradient(90deg, #01C1C1 0%, rgba(1, 193, 193, 0) 100%);
  transition: all 0.1s ease-in-out;
  border-radius: 10px;
  color: white !important;
}

.man_text.story_text h4 {
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}

.man_text.story_text p {
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  color: rgba(255, 255, 255, 0.5);
}

.like_dislike {
  display: flex;
  align-items: center;
}

.inner_like {
  width: 20px;
  height: 20px;
  background: #63758e;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
}
.like_part {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  color: rgba(255, 255, 255, 0.5);
  /* margin: 0 8px; */
}
/* Pagination start */

.pagination_ul {
  margin-top: 30px;
}
.pagination_ul ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.pagination_ul ul li {
  list-style: none;
  margin: 0 5px;
}
.pagination_ul ul li a {
  background: #495a71;
  padding: 2px 7px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-size: 15px;
  display: inline-block;
}

.pagination_ul ul li a:hover {
  text-decoration: none;
}
.pagination_ul ul li.active {
  color: #fff;
  font-size: 15px;
}
.man_user img {
  border-radius: 100%;
}
/* Pagination End */

.rdt_Table {
  background-color: transparent !important;
}

.rdt_TableHeadRow {
  background-color: transparent !important;
}
.rdt_TableCell div:first-child {
  overflow: visible !important;
  white-space: normal !important;
}


.user_management_list h3{
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
}
@media screen and (max-width: 575px) {
  .user_inner_partts,
  .user_management_list {
    padding: 30px;
  }
}

@media screen and (max-width: 480px) {
  .manage_table table {
    width: 280%;
  }
}

.add_button_set_align{
  background: #01C1C1;
  border-radius: 30px;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  border: 0px;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  margin-left: "auto";
  display: flex;
  margin-left: 75%;
}

.story_card_count {
  /* max-width: 48%; */
  width: 45%;
}
