.dash_partts.background{
    background-color: #545F70;
    margin: 40px 40px 40px 40px;
    padding: 30px;
    color: #ffff;
    border-radius: 15px;
}
.heading_set{
margin: 20px 40px;
color: #ffff;
font-size: 20px;
}
.dash_partts.background.allign{
margin: -0px 30px;
}