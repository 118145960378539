.footer_partttss{
    background: #283342;
    height: 34px;
    line-height: 34px;
}

.footer_inner_partts{
    padding: 0 60px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer_inner_partts p{
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
}

.footer_inner_partts ul{
    display: flex;
    margin-bottom: 0px;
}

.link_agreement{
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    text-decoration: none;
    font-weight: 500;
    margin-left: 10px;
}

.footer_inner_partts ul li{
    padding: 0 10px;
    list-style: none;
    position: relative;
}

.footer_inner_partts ul li:before{
    content: "|";
    position: absolute;
    left: 0;
    font-size: 13px;
    color: #fff;
    top: 2px;

}

.footer_inner_partts ul li:first-child:before{
    content: none;
}