.header_partttss{
    background: #384659;
    min-height: 60px;
    padding: 10px 60px 0 60px;
    display: flex;
    justify-content: space-between;
}

.logout-design{
    padding: 20px;
    padding-right: 20px;
    /* background-color: #01C1C1; */
}
.dasg__imgg img{
    margin-right: 15px;
    padding-bottom: 5px;
}
.notification-design{
    padding: 20px;
    margin-right: 8px;
}
.notification-design:hover{
    background-color: #01C1C1;
    border-radius: 5px;
}
.notification_image img{
    margin-right: 18px;
}
.logout-design:hover{
    background-color: #FF000047;
    border-radius: 5px;
}

/* .hypeu_logout .dasg__imgg:hover{
    background-color: #01C1C1;
} */

.search_button_div{
    position: absolute;
    top: 0px;
    right: 0rem !important;
    text-indent: -999px;
    z-index: 9;
    width: 40px;
    background: transparent;
}
.search_button_div a{
    width: 100%;
    height: 100%;
    display: block;
    z-index: 9;
    position: relative;
    background: transparent;
}

.search_button{
    padding: 4px;
    height: 38px;
    width: 7rem;
    border: 0;
    border-radius: 4px;
    background-color: #E3EBF3;
    opacity: 0.8;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    z-index: 2rem;
    color: #6c757d;
}

.header_texfieldt{
    position: relative;
}
.header_texfieldt .search_boxxx{
    /* background: #E3EBF3; */
    background: rgba(227,235,243,0.5);
    border-radius: 4px;
    width: 440px;
    height: 38px;
    color: white !important;
    font-size: 17px;
}

.search_imgg{
    position: absolute;
    top: 6px;
    right: 10px;
}

.header_date_and_time{
    display: flex;
    align-items: center;
}
.header_date{
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    padding-right: 15px;
}
.header_date span{
    display: inline-block;
    margin-right: 10px;
}

.header_time{
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    padding-left: 15px;
    position: relative;
}

.header_time:before{
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: #949FAF;
    left: 0;
    top: 10px;
}
.hypeu_logout a{
    padding: 10px 20px;
    /* border: 1px solid #fff; */
    /* background: #ff0000; */
}

.header_texfieldt .search_boxxx::placeholder{
    color: white;
}

.hypeu_logout .dasg__imgg{
    padding-right: 0px;
}
@media screen and (max-width:1199px){
    .header_texfieldt .search_boxxx{
        width: 250px;
    }
    .usermaageleft{
        margin-bottom: 25px;
    }
}

@media screen and (max-width:991px){
    .header_texfieldt .search_boxxx {
        width: 180px;
    }

    .header_texfieldt .search_boxxx::placeholder{
        font-size: 11px;
    }
}

@media screen and (max-width:575px){
    .header_partttss{
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .header_texfieldt{
        margin-bottom: 20px;
    }
    .header_date_and_time{
        padding-bottom: 20px;
    }
    
}