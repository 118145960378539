.setting_left_partts {
    background: #5b687a;
    border-radius: 15px;
    padding: 110px 25px 70px 20px;
}
.setting_left_inner_partts {
    padding-top: 40px;
}

.setting_right_partts_inner {
    background: #5b687a;
    border-radius: 15px;
    padding: 30px 10px;
}
.setting_active {
    background: #5b687a;
    border-radius: 15px;
    padding: 30px 10px;
    box-shadow: 0 0 10px rgb(231 214 255) !important;
}
.sand_box_partts h2,
.prd_partts h2 {
    text-align: center;
    color: #fff;
    font-size: 26px;
}

.toggle_switch {
    text-align: center;
}

.sandbox_row {
    display: flex;
    margin: 0 -10px;
}

.sand_left,
.sand_right {
    max-width: 49%;
    width: 100%;
    padding: 0 10px;
}

.sand_middle {
    max-width: 9%;
    width: 100%;
    padding: 0 10px;
}

.setting_left_partts label {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 155px;
    display: block;
}
.mail_login {
    margin-bottom: 0;
}
.sand_text_box {
    height: 40px;
    width: 23%;
    margin: 0 3px 15px;
    background: #4d596a;
    border-radius: 6px;
    border: 0px;
    color: #b8c0ca;
    font-size: 12px;
    padding: 0 5px;
}
.mail_login.stripe .sand_text_box {
    width: 100%;
    margin: 0 0 15px 0;
}

.mail_login {
    margin-bottom: 15px;
}
.mail_login .host_partts {
    max-width: 100%;
    width: 100%;
    margin: 0 -6px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.columnss {
    max-width: 50%;
    width: 100%;
    padding: 0 6px;
}
.mail_login .host_partts .sand_text_box {
    max-width: 100%;
    width: 100%;
}
.sand_text_box::placeholder {
    color: #b8c0ca;
    font-size: 12px;
}
.host_partts label,
.mail_login.stripe {
    color: #fff;
    font-size: 14px;
}
.sand_text_box:focus {
    background: #4d596a;
    color: #b8c0ca;
    font-size: 12px;
    outline: 0px;
    box-shadow: none;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
}
.toggle-switch input[type="checkbox"] {
    display: none;
}
.toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
    position: absolute;
    content: "";
    left: 2px;
    top: 2px;
    width: 21px;
    height: 21px;
    background-color: #aaa;
    border-radius: 50%;
    transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
    transform: translateX(25px);
    background-color: #bdbdbd;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
    background-color: #1d242e;
}

@media screen and (max-width: 1371px) {
    .setting_active,
    .setting_right_partts_inner {
        padding: 30px 3px;
    }
}

@media screen and (max-width: 1199px) {
    .sand_left,
    .sand_right {
        max-width: 100%;
    }
    .sandbox_row {
        flex-wrap: wrap;
    }
    .sand_middle {
        max-width: 100%;
        margin: 30px 0;
        text-align: center;
    }
}

@media screen and (max-width: 420px) {
    .sand_text_box {
        width: 100%;
    }
}
.addnew_modal .mail_modal_button {
    background: #01c1c1;
    border-radius: 30px;
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    border: 0px;
    font-weight: 400;
    margin-top: 50px;
    font-size: 15px;
    color: #fff;
    margin-top: 30px;
}
.text_box,
.text_box:focus {
    background: #455060;
    height: 40px;
    border-radius: 20px;
    display: block;
    width: 100%;
    color: #fff;
    border: 0px;
    padding: 0 20px;
    outline: none;
    box-shadow: none;
}
.up_submit_but .upload_submit {
    background: #01c1c1;
    border-radius: 30px;
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    border: 0px;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    display: block;
    cursor: pointer;
    width: 100%;
}

.up_submit_but .upload_submit:focus {
    outline: 0;
    box-shadow: none;
}
.text_box_textarea,
.text_box_textarea:focus {
    background: #455060;
    color: #fff;
}
.addnew_modal {
    display: flex;
    justify-content: flex-end;
    padding-right: 60px;
}
.setting_left_partts.fix_design {
    background: #5b687a;
    border-radius: 15px;
    padding: 96px 20px 113px 20px;
}

.allowAddCard .form-check-input[type="checkbox"] {
    height: 20px;
    width: 40px;
}

.allowAddCard label {
    margin-bottom: -4px;
    margin-left: 10px;
}

.note-container {
    background-color: rgb(231 214 255 / 70%);
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    color: black;
    max-width: 90%;
}

p {
    font-size: 16px;
}

strong {
    font-weight: bold;
    color: #785ca0;
}
