.management_field label{
    color: #fff;
    font-size: 14px;
}
.manage_drop{
    display: flex;
    justify-content: flex-end;
}
.management_field .text_box, .management_field .text_box:focus {
    background: #283342;
}
.management_field{
    padding: 30px 0 48px 0;
}

.management_submit{
    display: flex;
    justify-content: center;
    align-items: center;
}
body .cke_editable.cke_editable_themed  {
    color:rgb(255, 255, 255);
    background:rgba(73, 88, 97, 0.979) !important;
    }
#cke_1_top{
    color:rgb(255, 255, 255);
    background:rgba(91 104 122 / 58%) !important;
}

.management_submit .upload_submit{
    background: #1D242E !important;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    border: 0px;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    cursor: pointer;
}
.management_submit .upload_submit:hover{
    outline: 0px;
    /* box-shadow: none; */
    
    color: white !important;
    background: linear-gradient(90deg, #01C1C1 0%, rgba(1, 193, 193, 0) 100%) !important;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 40%);
    transition: all 0.1s ease-in-out;
    border-radius: 4px;
}
.download_loading.fix_loader:hover{
    /* border-radius: 30px; */
    box-shadow: none;
}
.btn-check:focus+.btn, .btn:focus{
    box-shadow: none;
}
.dash_partts.manage_dash{
padding-bottom: 250px;
}
.ReactImagePickerEditor input,
.ReactImagePickerEditor select
{
    background:rgba(22,22,22,0.6) !important
}
.crop-container {
    position: relative;
    width: 272px;
    height: 408px;
  
    /* background: linear-gradient(to right, #cbd4e1 8px, transparent 8px) 0 0,
      linear-gradient(to right, #cbd4e1 8px, transparent 8px) 0 100%,
      linear-gradient(to left, #cbd4e1 8px, transparent 8px) 100% 0,
      linear-gradient(to left, #cbd4e1 8px, transparent 8px) 100% 100%,
      linear-gradient(to bottom, #cbd4e1 8px, transparent 8px) 0 0,
      linear-gradient(to bottom, #cbd4e1 8px, transparent 8px) 100% 0,
      linear-gradient(to top, #cbd4e1 8px, transparent 8px) 0 100%,
      linear-gradient(to top, #cbd4e1 8px, transparent 8px) 100% 100%; */
  
    background-repeat: no-repeat;
    background-size: 70px 70px;
  }