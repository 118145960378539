.fixProgressBar{
    width: 15rem !important;
    /* width: 16rem; */
    /* text-align: center !important; */
    /* text-align: center;
    display: flex; */
    /* position: absolute; */
    
    /* height: 2rem; */
    
    /* display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; */
}
.man_text_userlevel{
    padding-left: 2.5rem;
}
.man_text_common{
    padding-left: 4.5rem;
}
.man_text_rare{
    padding-left: 3rem;
}
.man_text_epic{
    padding-left: 3rem;
}
.man_text_nftcount{
    padding-left: 3rem;
}

.progress_new_label{
    position: absolute;
    left: 65%;
    top: 50%;
    transform: translate(5%,-50%);
    color: black;
    width: 3.6rem;
}

.progress_label{
    position: relative;
}
.view_hover span{
    box-shadow: 0px 0px 15px rgb(0 0 0 / 30%);
    letter-spacing: 1px;
    padding: 8px;
    border-radius: 5px;
    font-weight: bold;
}
.view_hover span:hover{
    color: white !important;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 50%);
    /* background: linear-gradient(90deg, #01C1C1 0%, rgba(1, 193, 193, 0) 100%); */
    background-color: #01C1C1;
    transition: all 0.1s ease-in-out;
    border-radius: 5px;
}