.editNotificationStyle {
  height: 1rem;
  width: 1rem;
  margin-left: 1.2rem;
}
.notification_key {
  margin-left: 0.8rem;
}
.setting_left_partts {
  background: #5b687a;
  border-radius: 15px;
  padding: 82px 20px 34px 20px;
}
.setting_left_inner_partts {
  padding-top: 40px;
}

.setting_right_partts_inner {
  background: #5b687a;
  border-radius: 15px;
  padding: 30px 10px;
}
.setting_active {
  background: #5b687a;
  border-radius: 15px;
  padding: 30px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.sand_box_partts h2,
.prd_partts h2 {
  text-align: center;
  color: #fff;
  font-size: 26px;
}

.toggle_switch {
  text-align: center;
}

.sandbox_row {
  display: flex;
  margin: 0 -10px;
}

.sand_left,
.sand_right {
  max-width: 61%;
  width: 100%;
  padding: 0 10px;
}

.sand_middle {
  max-width: 2%;
  margin-left: -20px;
  width: 100%;
  padding: 0 10px;
}

.setting_left_partts label {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 155px;
  display: block;
}
.notification_container {
  margin-bottom: 0;
}
.notification_input_field {
  height: 40px;
  width: 23%;
  margin: 0 3px 15px;
  background: #4d596a;
  border-radius: 6px;
  border: 1px solid #bdbdbd;
  color: #b8c0ca;
  font-size: 12px;
  padding: 0 5px;
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.addnew_modal .notification_update_button {
  background: #4d596a;
  margin-left: 36%;
  z-index: 3;
  border-radius: 30px;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  border: 0px;
  font-weight: 400;
  margin-top: 50px;
  font-size: 15px;
  color: #fff;
  margin-top: 30px;
}
.addnew_modal .notification_update_button:hover {
  background-color: #bdbdbd;
  font-weight: bold;
  color: #4d596a;
}
.notification_row {
  justify-content: center;
  align-items: center;
}
.notification_container.stripe .notification_input_field {
  width: 100%;
  margin: 0 0 15px 0;
}

.notification_container {
  margin-bottom: 15px;
}
.notification_container .container_inner_part {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.notification_container .container_inner_part .notification_input_field {
  max-width: 100%;
  width: 100%;
}
.notification_input_field::placeholder {
  color: #b8c0ca;
  font-size: 12px;
}
.container_inner_part label,
.notification_container.stripe {
  color: #fff;
  font-size: 14px;
}
.notification_input_field:focus {
  background: #4d596a;
  color: #b8c0ca;
  font-size: 12px;
  outline: 0px;
  box-shadow: none;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 21px;
  height: 21px;
  background-color: #aaa;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(25px);
  background-color: #bdbdbd;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #1d242e;
}

@media screen and (max-width: 1371px) {
  .setting_active,
  .setting_right_partts_inner {
    padding: 30px 35px;
  }
}

@media screen and (max-width: 1199px) {
  .sand_left,
  .sand_right {
    max-width: 100%;
  }
  .sandbox_row {
    flex-wrap: wrap;
  }
  .sand_middle {
    max-width: 100%;
    margin: 30px 0;
    text-align: center;
  }
}

@media screen and (max-width: 420px) {
  .notification_input_field {
    width: 100%;
  }
}
.text_box,
.text_box:focus {
  background: #455060;
  height: 40px;
  border-radius: 20px;
  display: block;
  width: 100%;
  color: #fff;
  border: 0px;
  padding: 0 20px;
  outline: none;
  box-shadow: none;
}
.up_submit_but .upload_submit {
  background: #01c1c1;
  border-radius: 30px;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  border: 0px;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  display: block;
  cursor: pointer;
  width: 100%;
}

.up_submit_but .upload_submit:focus {
  outline: 0;
  box-shadow: none;
}
.text_box_textarea,
.text_box_textarea:focus {
  background: #455060;
  color: #fff;
}


.useKeyStyle{
  padding: 1px 4px;
  background: #3a4a5e;
  border-radius: 10px;
  opacity: 0.8;
  margin: 2px;
  text-align: center;
}