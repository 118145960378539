/* .man_user{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -30px;
} */

.text_box_textarea{
    border: 0px;
}

.man_text.adjust{
    margin-left: 0px;
    width: 100%;
}