/* FAQ css start */

/* .faq_sec .faq_cntn .card {
    background-color: #495a71;
    padding: 0;
  }
  .faq_sec .faq_cntn .card h2 button {
    padding: 0;
    color: #fff;
    text-decoration: none;
    position: relative;
    border: none;
    font-size: 15px;
  }
  .faq_sec .faq_cntn .card h2 button:focus {
    border: none;
    box-shadow: none;
    outline: none;
  }
  .faq_sec .faq_cntn .card h2 button::after {
    content: "-";
    position: absolute;
    right: 5px;
    top: 0;
    width: 2px;
    height: auto;
    font-size: 20px;
  }
  .faq_sec .faq_cntn .card h2 button.collapsed::after {
    content: "+";
    position: absolute;
    right: 5px;
    top: 0;
    width: 2px;
    height: auto;
    font-size: 20px;
  }
  .faq_sec .faq_cntn .card .card-header {
    padding: 15px 25px 15px 15px;
    background-color: transparent;
  }
  .faq_sec .faq_cntn .card .card-body {
    padding: 15px 30px 15px 15px;
    color: #ffffffc0;
    font-size: 14px;
  } */
/* FAQ css end */

.header_logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hypeu_about_banner {
    background-image: url(../../assets/images/svg_images/faq.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 350px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hypeu_about_banner:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
.hypeu_about_banner h1 {
    font-size: 65px;
    font-weight: 900;
    color: #fff;
    z-index: 9;
    margin-top: 2.5rem;
}

.faq_cntn.accordion {
    background-color: transparent;
}
.faq_cntn .accordion-item {
    background-color: #495a71 !important;
    border: none !important;
}
.faq_cntn .accordion-item .accordion-header button.accordion-button {
    background-color: transparent;
    color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-top: none;
    border-right: none;
    border-left: none;
    box-shadow: none !important;
}
.faq_cntn .accordion-item .accordion-header button.accordion-button.collapsed {
    background-color: transparent;
}
.faq_cntn .accordion-item .accordion-body {
    color: #ffffffa6;
    font-size: 14px;
}
.faq_cntn .accordion-item .accordion-header button.accordion-button {
    filter: brightness(10.5) !important;
}
.faq_cntn .accordion-item .accordion-header button.accordion-button.collapsed {
    filter: brightness(10.5);
}
.faq_cntn .accordion-item .accordion-header button.accordion-button:focus {
    box-shadow: none;
}
.faq_edit_sec {
    margin-top: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.faq_edit_sec .fes_edit {
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    background-color: rgba(255, 255, 255, 0.356);
    box-shadow: 0px 4px 4px #39323254;
    border-radius: 4px;
    margin-right: 10px;
}
.faq_edit_sec .fes_edit:hover {
    cursor: pointer;
}
.faq_edit_sec .fes_delete {
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    background-color: rgba(255, 255, 255, 0.356);
    box-shadow: 0px 4px 4px #39323254;
    border-radius: 4px;
}
.faq_edit_sec .fes_delete:hover {
    cursor: pointer;
}
.faq_edit_sec .fes_edit img,
.faq_edit_sec .fes_delete img {
    margin-right: 8px;
}

.ft_cntn ul {
    padding: 0;
    margin-bottom: 0;
}
.ft_cntn ul a {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    margin-left: 10px;
}

.ft_cntn ul a:hover {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #40a9ff;
    text-decoration: none;
    margin-left: 10px;
}

.user_inner_partts {
    overflow-x: hidden;
}

@media screen and (max-width: 767px) {
    .user_inner_partts {
        padding: 0 0 30px !important;
    }
}

@media only screen and (max-width: 991px) {
    .hypeu_nav {
        padding: 20px 30px;
    }
    .hypeu_footer {
        padding: 5px 30px;
    }

    .ft_cntn ul a {
        font-size: 12px !important;
    }
}

@media only screen and (max-width: 968px) {
    .header_notification {
        margin-right: 6px !important;
    }

    .ft_cntn ul a {
        font-size: 12px !important;
    }

    .ft_cntn p {
        font-size: 12px !important;
    }
}
@media only screen and (max-width: 767px) {
    .header_logo {
        /* text-align: center; */
        margin-bottom: 15px;
    }
    .header_search {
        margin-bottom: 15px;
    }
    .header_search .form-inline {
        margin: 0 auto;
    }
    .header_date_and_time {
        justify-content: center;
    }
    .hypeu_terms_banner h1,
    .hypeu_privacy_banner h1,
    .hypeu_about_banner h1 {
        font-size: 45px;
        text-align: center;
    }
    .ft_cntn ul a {
        font-size: 12px !important;
    }
}
@media only screen and (max-width: 575px) {
    .hypeu_nav {
        padding: 15px 15px;
    }
    .hypeu_footer {
        padding: 5px 15px;
    }
    .ft_cntn {
        flex-direction: column;
    }
    .ft_cntn p {
        margin-bottom: 5px;
        font-size: 12px !important;
    }
    .header_search .form-inline {
        width: 100%;
    }
    .header_search button {
        margin: 0 !important;
    }
    .hypeu_terms_banner,
    .hypeu_privacy_banner,
    .hypeu_about_banner {
        height: 200px;
    }
    .hypeu_terms_banner h1,
    .hypeu_privacy_banner h1,
    .hypeu_about_banner h1 {
        font-size: 30px;
    }
    .main_cntn {
        padding: 55px 15px 45px;
    }
    .ft_cntn ul a {
        font-size: 12px !important;
    }
}
@media only screen and (max-width: 425px) {
    .header_date_and_time {
        justify-content: space-between;
    }
    .header_notification a,
    .header_logout a {
        padding: 7px 10px;
    }
    .header_date img {
        width: 16px;
    }
    .header_date span {
        font-size: 15px;
    }
    .ft_cntn ul a {
        font-size: 12px !important;
        margin-left: 5px !important;
    }
}
