.ifOHjV {
    padding-bottom: 60px !important;
}

.WQNxq {
    overflow-y: scroll !important;
}

.userActionAlign {
    margin-left: 5px;
}

.manage_table_name .rdt_TableCol > div > div[data-column-id="1"] {
    padding-left: 1rem;
}

.story_text {
    white-space: wrap;
}

.user_partts {
    /* background: #E5E5E5; */
    display: flex;
    justify-content: space-between;
}

.alert_p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 16px;
}

.modal-title {
    margin-bottom: 0;
    line-height: var(--bs-modal-title-line-height);
    font-size: 2rem;
    font-weight: bold;
}

.alert-button {
    background: red !important;
    margin-left: 1rem;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    padding: 0 40px;
    border: 0px;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    display: inline-block;
}

.alert-button:hover {
    /* background: greenyellow !important; */
    background: rgba(255, 0, 0, 0.8) !important;
    color: #fff !important;
}
.email-button:hover {
    background: rgba(69, 80, 96, 0.7) !important;
    color: #fff !important;
}

.right_panel {
    max-width: 80%;
    width: 100%;
    flex: 0 0 auto;
}

.user_inner_partts {
    padding: 70px 60px 35px 60px;
}
.total_users_report {
    display: flex;
    justify-content: space-between;
}

.total_users_left {
    max-width: 70%;
    width: 100%;
}

.total_users_right {
    max-width: 30%;
    width: 100%;
}
.download_report {
    display: flex;
    justify-content: flex-end;
}
.total_users_left h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #ffffff;
}

.total_and_new_user {
    /* display: flex; */
    /* justify-content: space-between; */

    margin-top: 4.6rem !important;
}

.t_userers_adjust {
    /* max-width: 48%; */
    /* width: 37%; */
}
.t_userers_size {
    padding: 30px 23px;
    background: #75859d;
    border-radius: 10px;
    display: flex;
    /* justify-content: space-between; */
    /* margin-left: -20px; */
    position: relative;
    transition: all 0.3s ease-in-out;
}
.total_users_report_box {
    /* display: flex; */
    justify-content: space-between;
    display: block !important;
}
.total_users_report_box .total_users_left {
    max-width: 100%;
}
.t_userers_size:hover {
    color: white !important;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 40%);
    background: linear-gradient(90deg, #01c1c1 0%, rgba(1, 193, 193, 0) 100%);
    transition: all 0.1s ease-in-out;
    border-radius: 10px;
}
.t_userers_loading {
    margin-left: 15px;
}

.tusers_heading h2 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
}

.tusers_heading h4 {
    font-weight: 400;
    font-size: 34px;
    line-height: 42px;
    color: #ffffff;
    margin-bottom: 0px;
}

.tusers_heading h4 sub {
    font-size: 11px;
    color: #fff;
}

.drop_partts121 button {
    background: #75859d;
    border: 0.4px solid #1d242e;
    border-radius: 4px;
    width: 180px;
    height: 40px;
    font-weight: 400;
    font-size: 15px;
    color: #ffffff;
    padding: 0 10px;
}
.drop_partts121 button:after {
    position: absolute;
    content: "";
    border: none;
    background: url(../../assets/images/drop_down.png) no-repeat;
    width: 11px;
    background-position: 98% center;
    height: 6px;
    margin-left: 14px;
    margin-top: 9px;
}

.user_management_list {
    padding: 26px 60px;
    background: #384659;
}

.manage_heading_search {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.manage_heading_search h2 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
}

.manage_texfieldt {
    width: 170px;
    height: 26px;
    position: relative;
}

.manage_search_boxxx,
.manage_search_boxxx:focus {
    width: 100%;
    height: 100%;
    background: #2c3949;
    border-radius: 26px;
    border: 0px;
    padding: 0 30px;
    font-size: 12px;
    line-height: 15px;
    color: #fff;
    outline: 0px;
    box-shadow: none;
}

.manage_search_boxxx::placeholder {
    font-size: 12px;
    line-height: 15px;
    color: #556578;
}

.search_imgg_manage {
    position: absolute;
    left: 10px;
    top: 0px;
}

.manage_table {
    background: #495a71;
    border-radius: 10px;
    /* overflow: hidden; */
    margin-top: 20px;
}
.manage_table table {
    margin-bottom: 0px;
}
.manage_table .table td,
.manage_table .table th,
.manage_table .table thead th {
    border-top: 0px;
    border-bottom: 1px solid #6d7d94;
    vertical-align: middle;
}

.manage_table .table th {
    padding: 10px 10px;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #ffffff;
}

.management_list_name {
    display: flex;
    align-items: center;
}

.man_user {
    width: 48px;
    height: 48px;
    border-radius: 100%;
}

.man_user img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

.man_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    margin-left: 10px;
    width: 150px;
    padding-right: 20px;
    /* max-width: 70%;
    width: 100%; */
}

.man_email a,
.man_email,
.man_ph a,
.man_ph {
    font-weight: 400;
    font-size: 10px;
    /* line-height: 5px; */
    color: rgba(255, 255, 255, 0.5);
}

.three_dotss .drop_partts button:after {
    content: none;
}

.three_dotss .drop_partts .dropdown-menu {
    min-width: 80px;
    padding: 10px;
    background: #637896;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.three_dotss .drop_partts .dropdown-menu a {
    padding: 0px;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #ffffff;
    display: flex;
    align-items: center;
}

.three_dotss .drop_partts .dropdown-menu a img {
    margin-right: 12px;
}

.three_dotss .drop_partts .dropdown-menu .dropdown-item:focus,
.three_dotss .drop_partts .dropdown-menu .dropdown-item:hover,
.three_dotss .drop_partts .dropdown-menu .dropdown-item.active,
.dropdown-item:active {
    background: transparent;
    color: #fff;
}

.dognut_partts {
    background: #495a71;
    border-radius: 10px;
    padding: 7px !important;
    margin-top: 20px;
    position: relative;
}

.data_counts {
    position: absolute;
    font-weight: 400;
    font-size: 25px;
    text-align: center;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
}
.show_header h4 {
    text-align: center;
    font-weight: bold;
}

/* Pagination start */

.management_list_name .story_user img {
    width: 48px !important;
    height: 50px I !important;
}

.usermaageleft .dognut_partt {
    margin-top: 0px !important;
}

.pagination_ul {
    margin-top: 30px;
}
.pagination_ul ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.pagination_ul ul li {
    list-style: none;
    margin: 0 5px;
}
.pagination_ul ul li a {
    background: #495a71;
    padding: 2px 7px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    font-size: 15px;
    display: inline-block;
}

.pagination_ul ul li a:hover {
    text-decoration: none;
}
.pagination_ul ul li.active {
    color: #fff;
    font-size: 15px;
}
.man_user img {
    border-radius: 100%;
}
/* Pagination End */

.rdt_Table {
    background-color: transparent !important;
}

.rdt_TableHeadRow {
    background-color: transparent !important;
}
.rdt_TableCell div:first-child {
    overflow: visible !important;
}
.rdt_TableCell div:first-child {
    overflow: visible !important;
    white-space: normal !important;
}

.manage_table ::-webkit-scrollbar {
    width: 15px;
}
.manage_table ::-o-scrollbar {
    width: 15px;
}
.manage_table ::-moz-scrollbar {
    width: 15px;
}
.manage_table ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.12);
    height: 12px;
}
.manage_table ::-o-scrollbar-track {
    background: rgba(0, 0, 0, 0.12);
    height: 12px;
}
.manage_table ::-moz-scrollbar-track {
    background: rgba(0, 0, 0, 0.12);
    height: 12px;
}
.manage_table ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.16);
    border-radius: 25px;
    height: 12px;
    width: 12px;
}
.manage_table ::-o-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.16);
    border-radius: 25px;
    height: 12px;
    width: 12px;
}
.manage_table ::-moz-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.16);
    border-radius: 25px;
    height: 12px;
    width: 12px;
}
.rdt_Pagination {
    background-color: #283342 !important;
    color: #fff !important;
}

.rdt_Pagination svg {
    fill: #fff !important;
}

.manage_table {
    position: relative;
}
.loader_1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: rgba(0,0,0,0.6); */
    z-index: 9;
}
.synccc_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: -75px;
    right: 0;
    bottom: -61px;
}
.pulse_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: -75px;
    right: 0;
    bottom: -61px;
}

.ifOHjV {
    padding-bottom: 60px !important;
}

.block_items {
    max-width: 48%;
}

@media screen and (max-width: 1199px) {
    .total_and_new_user {
        display: block;
    }
    .t_userers {
        max-width: 100%;
        margin-bottom: 20px;
    }
    .manage_table table {
        width: 160%;
    }
    .data_count {
        font-size: 20px;
        top: 64%;
    }
}

@media screen and (max-width: 991px) {
    .data_count {
        font-size: 40px;
        top: 52%;
    }
    .total_users_report {
        display: block;
    }
    .total_users_left,
    .total_users_right {
        max-width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .user_partts {
        display: block;
    }
    .right_panel {
        max-width: 100%;
        margin-top: 30px;
    }
}
._loading_overlay_overlay {
    background: rgba(0, 0, 0, 0.5);
}

.userFilter .dropdown-item.active,
.userFilter .dropdown-item:active {
    background-color: #01c1c1;
}

#userFilter {
    width: 7rem;
    background:#2C3949;
    border: 0px;
}

