.info_block {
    background-color: #515B6C;
    border-radius: 15px;
    padding: 20px;
    /* gap:3; */
    justify-content: space-around;
}


.item_info {
    background: #697281;
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    /* margin-bottom: 20px; */
    flex:0 0 14.66%;
    border-radius: 5px;
}

.item_info:hover {
    cursor: pointer;
    background: linear-gradient(90deg, #01c1c1 0%, rgba(1, 193, 193, 0) 100%);
    /* background: linear-gradient(90deg, #01c1c1 0%, rgba(1, 193, 193, 0) 100%); */

}

.item_info .info_value {
    font-size: 18px;
    font-weight: 700;
}

.item_info .info_title {
    font-size: 14px;
}