.dash_partts {
    padding: 40px 60px;
}

/* .css-79elbk{
    height: 100% !important;
} */
/* ._loading_overlay_wrapper{
    height: 100%;
} */
.dashboard_loader {
    height: 100%;
}

.dash_part_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dash_partts_early {
    padding: 40px 60px 0px;
}

.dash_partts_inner {
    padding: 0px 60px 40px;
}

.dash_part_heading_left h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #FFFFFF;
}

.dash_part_heading_left p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}

.drop_partts121 button,
.drop_partts121 button:hover,
.drop_partts121 button:focus {
    /* .drop_partts121 button, .drop_partts121 button:focus{ */
    background: #75859D;
    border: 0.4px solid #1D242E;
    border-radius: 4px;
    width: 180px;
    height: 40px;
    font-weight: 400;
    font-size: 15px;
    color: #FFFFFF;
    padding: 0 10px;
}

.drop_partts121 button:after {
    position: absolute;
    content: "";
    border: none;
    background: url(../../assets/images/drop_down.png) no-repeat;
    width: 11px;
    background-position: 98% center;
    height: 6px;
    margin-left: 14px;
    margin-top: 9px;
}

.active_accnt_partts {
    margin-top: 45px;
}

.default-padding {
    padding: 0px 0px 0px 0px;
}

.dropdown-menu.show {
    background-color: #75859D;
    width: 100%;
    /* padding: 5px; */
}

.dropdown-item {
    color: white;
}

/* .dropdown-menu.show a{
    color: black !important;
} */
/* .dropdown-menu.show:hover, .dropdown-menu.show a:hover, .dropdown-menu.show a:focus{
    background-color: #283342;
} */
.dropdown-item:hover {
    color: white !important;
    /* background-color: #283342; */
    background: linear-gradient(90deg, #01C1C1 0%, rgba(1, 193, 193, 0) 100%);
    box-shadow: 0px 0px 15px rgb(0 0 0 / 40%);
    transition: all 0.1s ease-in-out;
    border-radius: 4px;
}

.active_accnt_inner_partts {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7px;
}

.active_inner_inner_partts {
    max-width: calc(100%/7);
    width: 100%;
    padding: 0 7px;
}

.active_inner_list {
    background: #75859D;
    border-radius: 6px;
    padding: 20px 5px 20px 12px;
    height: 100%;
    min-height: 110px;
    transition: all 0.3s ease-in-out;
}

.active_inner_list:hover {
    color: white !important;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 40%);
    background: linear-gradient(90deg, #01C1C1 0%, rgba(1, 193, 193, 0) 100%);
    transition: all 0.1s ease-in-out;
    border-radius: 4px;
}

.active_inner_list h2 {
    font-weight: 400;
    font-size: 15px;
    /* word-wrap: break-word; */
    line-height: 15px;
    color: #C2CDDD;
}

.active_image_text {
    display: flex;
    align-items: center;
}

.active_text {
    font-weight: 400;
    font-size: 26px;
    color: #fff;
    line-height: 32px;
    margin-left: 10px;
}


.monthly_revenue_chart {
    background: #495A71;
    border-radius: 10px;
    padding: 10px;
}

.dash_chart_left h2,
.dash_chart_right h2 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.dog_chart {
    position: relative;
    height: auto;
}

.count_inner {
    position: absolute;
    top: 51%;
    left: 51%;
    transform: translate(-50%, -50%);
    font-weight: 400;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    color: #FFFFFF;
}

.nfts_sold {
    margin-top: 30px;
}

.sold_nft_inner {
    background: #495A71;
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 30px;
}

.sold_nft_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sold_nft_inner:hover {
    /* box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
    border: 1px solid #01C1C1;
    transition: all 0.3s ease-in-out; */
    color: white !important;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 40%);
    background: linear-gradient(90deg, #01C1C1 0%, rgba(1, 193, 193, 0) 100%);
    transition: all 0.1s ease-in-out;
    border-radius: 10px;
}

.sold_nft_leftpp {
    display: flex;
    align-items: center;
}

.sold_box {
    background: #39495F;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sold_nft_leftpp h2 {
    font-weight: 400;
    font-size: 22px;
    color: #fff;
    margin-left: 20px;
    margin-bottom: 0px;
}

.sold_nft_rightpp {
    font-weight: 400;
    font-size: 30px;
    color: #01C1C1;
}


.top_three_nfts {
    background: #495A71;
    border-radius: 10px;
    padding: 20px 50px 20px 20px;
}

.top_three_nfts h2 {
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    margin-bottom: 20px;
}

.stry_man {
    width: 50px;
    height: 50px;
}

    {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
}

.nfts_user_partts {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nft_user_left {
    max-width: 40%;
    width: 100%;
    display: flex;
    align-items: center;
}

.stry_man {
    margin-right: 10px;
}

.top_three_nfts .storyman_texttt h2 {
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    margin-bottom: 5px;
    word-break: break-word;
}

.storyman_texttt p {
    font-size: 13px;
    color: #fff;
    margin-bottom: 0px;
}

.nft_user_middle {
    max-width: 30%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.nft_user_middle span {
    margin: 5px 5px;
}

.nft_user_right {
    max-width: 30%;
    width: 17%;
    text-align: center;
    border-radius: 10px;
}

.nfts_user_partts {
    margin: 10px 0;
}

.storyman_texttt {
    max-width: 60%;
    width: 100%;
}


@media screen and (max-width:1199px) {
    .active_inner_inner_partts {
        max-width: calc(100%/3);
        margin-bottom: 10px;
    }

    .count_inner {
        top: 64%;
        font-size: 24px;
    }

    .top_three_nfts {
        padding: 10px;
    }

    .top_three_nfts .storyman_texttt h2 {
        font-size: 14px;
    }

    .storyman_texttt {
        max-width: 45%;
    }

    .stry_man {
        width: 40px;
        height: 40px;
    }

    .sold_nft_leftpp h2 {
        font-size: 16px;
    }

    .sold_nft_rightpp {
        font-size: 20px;
    }
}

@media screen and (max-width:991px) {
    .dash_chart_right {
        margin-top: 25px;
    }

    .count_inner {
        top: 54%;
    }
}

@media screen and (max-width:575px) {
    .active_inner_inner_partts {
        max-width: calc(100%/2);
    }

    .dash_partts {
        padding: 20px 30px;
    }
}

@media screen and (max-width:420px) {
    .dash_part_heading {
        flex-wrap: wrap;
    }

    .active_inner_inner_partts {
        max-width: calc(100%/1);
    }

    .nfts_user_partts {
        flex-wrap: wrap;
    }

    .nft_user_left {
        max-width: 100%;
    }

    .nfts_user_partts {
        margin: 10px 0 20px 0;
    }

    .nft_user_middle {
        max-width: 40%;
    }
}

.drop_partts121 button,
.drop_partts121 button:hover,
.drop_partts121 button:focus {
    background: #75859D;
    border: 0.4px solid #1D242E;
    border-radius: 4px;
    width: 180px;
    height: 40px;
    font-weight: 400;
    font-size: 15px;
    color: #FFFFFF;
    padding: 0 10px;
}


.badges {
    width: 20px;
}

.filterSection {
    height: 200px;
    background: #39495F;
    margin: 40px 80px 0;
    border-radius: 25px;
    padding: 15px;
    color: white;
    display: inline-flex;
}

.filterSection h5 {
    color: white;
}

.dateRangebox {
    background: #262730;
    border: 0px;
    border-radius: 5px;
    /* padding: 2px 2px; */
}



/****** Dip design implementation start ******/
.stry_man.nft_user_img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: auto;
    flex: 0 1 auto;
}

.stry_man.nft_user_img img {
    width: auto;
    /* max-width: 60px; */
    height: 60px;
    max-height: 60px;
    border-radius: 6px;
}

.nfts_user_list {
    background: #2833426e;
    padding:0;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding-right: 15px;
}

.nfts_user_list .nft_user_left {
    flex: 1 1 350px;
    max-width: none;
    width: 350px;
}

.top_three_nfts.top_nft {padding-right: 20px;}

/****** Dip design implementation end ******/