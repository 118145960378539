.ReactTags__tags {
  position: relative;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block;
  position: relative;
  margin: 5px 0;
}
.ReactTags__tagInput:after {
  content: "";
  background: url(../../../assets/images/carbon_add-alt.png) center center
    no-repeat;
  width: 40px;
  height: 100%;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border-left: 1px solid rgb(203, 203, 203);
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 100%;
  min-height: 43px;
  /* margin: 5px; */
  font-size: 12px;
  /* border: 1px solid #eee; */
  border: none;
  min-width: 150px;
  background: #464f5c;
  border-radius: 5px;
  padding: 0 50px 0 10px;
  color: #fff;
  font-size: 16px;
  outline: none;
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  /* border: 1px solid #ddd; */
  border: none;
  background: #464f5c;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 7px 10px 7px 20px;
  margin: 5px 5px 5px 0;
  border-radius: 5px;
  font-size: 16px;
}
.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}
