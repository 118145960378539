.filter_card {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.495);
  overflow: hidden;
}
.filter_card .card-header {
  /* background: linear-gradient(90deg, #01c1c1 50%, rgba(1, 193, 193, 0) 100%);     */
  background: #283342;
  padding: 15px 15px;
}

.filter_card .card-body {
  /* background: #CFF2F7; */
  background: #455060;
}

.filter_card .form-check label {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
}

.form-check-input {
  background-color: transparent;
  border: 1px solid #ddddddd1;
}

.form-check-input:checked {
  background-color: #0169c9;
  border: 2px solid rgb(255 255 255);
  background-size: 13px;
}

.filter_card .card-body .form-label {
  font-weight: 500;
  color: #fff;
}
.filter_card .card-body input {
  min-height: 46px;
  background: #b3e2e8;
  color: #3b3b3b;
  border: none;
  border-radius: 5px;
}

/* .filter_card .card-body button:not(.react-datepicker__navigation) {
    min-height: 46px;
    background: #0171C2;
    border-radius:5px
} */

.filter_card .card-body button:not(.react-datepicker__navigation) {
  background: #75859d;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 20px;
  cursor: pointer;
  /* width: 150px; */
  height: 46px;
  line-height: 30px;
  color: #fff;
}

.filter_card .card-body button:not(.react-datepicker__navigation):hover {
  background: linear-gradient(90deg, #01c1c1 50%, rgba(1, 193, 193, 0) 100%);
}
