.typFile {
    display: none;
  }
  
  .custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 20px;
    cursor: pointer;
    width: 150px;
    height: 45px;
    line-height: 30px;
  }

  .custom-file-upload:hover{
    color: white !important;
    /* background-color: #283342; */
    background: linear-gradient(90deg, #01C1C1 0%, rgba(1, 193, 193, 0) 100%);
    box-shadow: 0px 0px 15px rgb(0 0 0 / 40%);
    transition: all 0.1s ease-in-out;
    border-radius: 4px;
  }

  .plus_minus { background: none; box-shadow: none; border-style: none; height: 25px; width: 25px;}
  .plus_minus img {width: 25px;}

  .reactEasyCrop_CropArea {border: none;}