.nft_collect_bottom_details.story_bottom{
    display: block;
}

.nft_collect_inner_body {
    min-height: 100vh;
}
.nft_collect_inner_body_story {
    min-height:auto
}

.story_title{
    margin-top: 12px;
}
.story_title h5{
    font-weight: bold;
}

.nft_collect_bottom_details.story_bottom .nft_collect_details_left{
    max-width: 100%;
}
.nft_collect_bottom_details {
    padding: 10px 10px;
    /* display: flex; */
    justify-content: space-between;
    align-items: flex-start;
    min-height: 235px;
}

.nft_collect_details_left {
    max-width: 100%;
    width: 100%;
    min-height: 147px;
}

.nft_collect_details_left h2{
    margin-top: 15px;
    margin-bottom: 20px;
}

.nft_collect_bottom_details.story_bottom .nft_collect_details_right{
    max-width: 100%;
}
.user_create_date span{
    color: rgba(255,255,255,0.7);
    font-style: italic;
    font-size: 16px;
    margin-top: 20px;
    display: block;
}
.nft_collect_bottom_details.story_bottom .football_datee{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nft_collect_bottom_details.story_bottom h2 a{
    color: #fff;
    font-size: 18px;
    margin: 10px 0;
    display: inline-block;
}

.nft_collect_bottom_details.story_bottom p{
    color: #fff;
    font-size: 14px;
    margin: 0px;
    padding: 0px;
}

.view_but.story_but{
    text-align: center;
    margin-top: 20px;
}