.ifOHjV{
    padding-bottom: 0px !important;
}

.sc-dmRaPn.gelpCx{
    min-height: auto !important;
}

.sc-dmRaPn.gelpCx{
    overflow-y: auto !important;
}

.more_button{
    background: #75859D;
    border: 0.4px solid #1D242E;
    border-radius: 4px;
    width: 70px;
    height: 40px;
    font-weight: 400;
    font-size: 15px;
    color: #FFFFFF;
    padding: 0 10px;
}

.more_button_div{
    margin-top: 0.5rem;
}
