.kevin_bio {
    padding: 60px 60px 35px 60px;
}

.kevin_inner_bio {
    background: #545f70;
    border-radius: 15px;
    padding: 30px;
    display: flex;
    /* flex-wrap: wrap; */
}

.edition_center_right.adjust {
    padding-top: 1.2rem;
    text-align: left;
    /* margin: 1.3rem; */
    margin-left: 0.5rem;
}

.kevin_inner_image {
    width: 120px;
    height: 200px;
}

.kevin_inner_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}
.kevin_inner_text {
    max-width: 84%;
    width: 100%;
    margin-left: 25px;
}

.kevin_inner_title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.kevin_inner_title p {
    margin-bottom: 0px;
}
.kevin_inner_title p .minted {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
}

.kevin_inner_title p .devider_minted {
    display: inline-block;
    margin: 0 20px;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
}

.choice_sign .kevin_number {
    font-weight: 400;
    font-size: 17px;
    color: #fff;
    margin-left: 10px;
}
.kevin_inner_heading {
    margin-top: 15px;
}
.kevin_inner_heading h3 {
    font-weight: 400;
    font-size: 26px;
    color: #fff;
    margin-bottom: 6px;
}

.kevin_inner_heading h4 {
    font-weight: 400;
    font-size: 20px;
    color: #fff;
    margin-bottom: 0px;
}

.kevin_badges {
    display: flex;
    align-items: center;
    margin-top: 14px;
}
.kevin_badges ul {
    display: flex;
    align-items: center;
    padding-left: 0px;
    margin-bottom: 0px;
}
.kevin_badges ul li {
    list-style: none;
    margin: 0 3px;
}

.kevin_common {
    margin-left: 30px;
}

.kevin_inner_para p {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 0px;
}

.edition_kevin {
    padding: 0px 60px 0px 60px;
    margin-bottom: 60px;
}
.edition_inner {
    height: 100%;
}

.edition_inner_header {
    display: flex;
    justify-content: start;
}

.edition_inner_header h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 21px;
    margin-bottom: 20px;
    color: #fff;
    margin-right: 30px;
}

.make_offer {
    border: 1px solid #ffffff;
    border-radius: 30px;
    height: 30px;
    display: inline-block;
    padding: 0 20px;
    color: #fff;
    font-weight: 400;
    font-size: 13px;
    line-height: 30px;
}

.edition_inner_inner {
    background: #545f70;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 20px;
    padding-top: 0;
    height: 25rem;
    overflow: auto;
}

.edition_inner_inner_part {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.edition_inner_center {
    /* max-width: 33%; */
    /* width: 100%; */
    margin: 10px 4px;
    margin-left: 0px !important;
}

.edition_center_left {
    display: flex;
    align-items: center;
}

.edition_user {
    width: 36px;
    height: 36px;
}
.edition_user img {
    border-radius: 50px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.edition_user_text {
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    margin-left: 10px;
    word-break: break-word;
}

.edition_user_text {
    font-size: 12px;
    margin-left: 0;
}

.edition_center_middle {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    text-align: left;
    /* margin-left: 2.2rem; */
}
.padding-lefts {
    padding-left: 58px !important;
}

.edition_center_right {
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    text-align: left;
}

.edition_leftts {
    /* height: 100%; */
}
.hermis_bottom.fix_design {
    border-radius: 10px 10px 0px 0px;
    padding-left: 26px;
    padding-right: 20px;
    /* margin-top: -20px; */
    padding-bottom: 10px;
    margin-left: -20px;
    margin-right: -20px;
}

@media screen and (max-width: 991px) {
    .hermis_bottom.fix_design {
        justify-content: space-between;
    }
}
h4 {
    color: #fff;
    font-size: 15px;
}

@media screen and (max-width: 991px) {
    .edition_inner {
        height: auto;
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 767px) {
    .kevin_bio {
        padding: 35px 20px;
    }
    .edition_kevin {
        padding: 0 20px;
    }
}
@media screen and (max-width: 575px) {
    .kevin_inner_bio {
        flex-wrap: wrap;
    }
    .kevin_inner_text {
        max-width: 100%;
        margin-left: 0;
        margin-top: 25px;
    }
    .edition_center_left {
        flex-wrap: wrap;
        display: block;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    .edition_user {
        margin: 5px auto;
    }
    .kevin_badges {
        flex-wrap: wrap;
    }
    .kevin_common {
        margin-left: 0;
        margin-top: 20px;
    }

    .choice_sign {
        margin-top: 20px;
    }
}

.nft_offers .man_user {
    width: 63px;
    height: 32px;
}
