.user_details_top_partts {
    padding: 60px;
    min-height: 100px;
}

/* .css-79el10k{
    height: 100%;
} */
.nft_top {
    margin: 0 -13px;
    display: flex;
    flex-wrap: wrap;
}

.nft_inner_inner.adjust_card {
    margin-top: 1rem;
    min-height: 100px;
}

.nft_inner.adjust_card {
    max-width: calc(100%) !important;
    /* transition: all 0.3s ease-in-out; */
}

/* .nft_inner_inner.adjust_card:hover{
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
    border: 1px solid #01C1C1;
    transition: all 0.3s ease-in-out;
} */

.nft_inner {
    max-width: calc(100%/6);
    width: 100%;
    padding: 0 13px;
    /* height: 100%; */
}

.nft_inner_inner {
    background: #75859D;
    border-radius: 8px;
    padding: 10px 10px 10px 10px;
    height: 100%;
    /* min-height: 100px; */
    transition: all 0.3s ease-in-out;
}

.nft_inner_inner:hover {
    color: white !important;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 40%);
    background: linear-gradient(90deg, #01C1C1 0%, rgba(1, 193, 193, 0) 100%);
    transition: all 0.1s ease-in-out;
    border-radius: 10px;
}

.nft_inner_inner h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #C2CDDD;
    margin-bottom: 20px;
}

.nft_inner_inner h2 {
    font-weight: 400;
    font-size: 22px;
    line-height: 15px;
    color: #2BFAFA;
    margin-top: 0px;
}

.nft_bottom {
    margin-top: 40px;
}

.hermis_top {
    padding: 20px 20px 48px 20px;
    background: #545F70;
    border-radius: 15px 15px 0 0;
}

.george_hermis_user {
    display: flex;
    align-items: center;
}

.hermis_user_image {
    position: relative;
}

.hermis_user_image img {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    object-fit: cover;
}

.greenn_bg {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #2ED47A;
    border: 3px solid #455060;
    position: absolute;
    bottom: 10px;
    right: -10px;
}

.hermis_user_left {
    margin-right: 30px;
}


.hermis_user_right h2 {
    font-weight: 400;
    font-size: 26px;
    line-height: 35px;
    color: #FFFFFF;
}

.hermis_user_right h2 span {
    display: block;
    font-size: 22px;
}

.hermis_listing {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.hermis_listing .more_but {
    padding: 0px 20px;
    height: 30px;
    line-height: 25px;
    background: transparent;
    border: 1px solid #fff;
    box-shadow: 0px 6px 12px rgb(0 0 0 / 3%);
    border-radius: 6px;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    margin: 0 5px;
}

.hermis_listing ul {
    display: flex;
    padding-left: 0;
    margin-bottom: 0px;
    flex-wrap: wrap;
}

.hermis_listing ul li {
    list-style: none;
    margin: 5px 6px;
}

.state_parttsss {
    /* margin-top: 34px; */
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: center;
}

.state_parttsss h2 {
    font-weight: 400;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 10px;
}

.height_weight {
    display: flex;
    margin-top: 35px;
}

.height_weight label {
    height: 35px;
    padding: 0 15px;
    background: #434D5B;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    display: inline-block;
    margin-right: 13px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 35px;
}

.hermis_bottom {
    background: #3D4857;
    border-radius: 0px 0px 15px 15px;
    padding: 17px 30px;
    display: flex;
    justify-content: space-between;
}

.hermis_bottom span {
    font-weight: 400;
    font-size: 14px;
    /* line-height: 14px; */
    color: #FFFFFF;
    display: inline-block;
    margin-left: 10px;
}

.nfts_collectable {
    background: #545F70;
    border-radius: 15px;
    padding: 15px 10px;
    min-height: 300px;
}

.delete_nft_partts {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.delete_nft_partts h2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
}

.delete_nft_partts h3 a img {
    margin-right: 10px;
}

.delete_nft_partts h3 a {
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
}

.nft_man_partts {
    display: flex;
    flex-wrap: wrap;
    padding-top: 40px;
}

.nft_man_inner_partts {
    max-width: calc(100%/3);
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.nft_mansss {
    margin-right: 10px;
}

.nft_mansss img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    object-fit: cover;
}

.nft_manss_heading h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #fff;
    margin-bottom: 2px;
}

.nft_manss_heading label {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #fff;
    margin-bottom: 0px;
}

.story_partts {
    background: #545F70;
    border-radius: 15px;
    padding: 15px 10px;
    margin-top: 20px;
    min-height: 300px;
}

.football_match {
    margin-top: 22px;
    display: flex;
    align-items: center;
}

.football_img {
    margin-right: 10px;
}

.football_img img {
    border-radius: 6px;
    width: 140px;
    height: 100px;
    object-fit: cover;
}

.football_datee {
    display: flex;
    align-items: center;
}

.love_football {
    margin-right: 20px;
}

.love_football img {
    width: 16px;
    height: 16px;
}

.love_football span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    margin-left: 10px;
}

.football_innr_text a {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
}

.football_innr_text p,
.football_innr_text p a {
    font-weight: 400;
    font-size: 14px;
    line-height: 10pxpx;
    color: #FFFFFF;
    margin-top: 12px;
}

.bio_user {
    padding: 30px 50px 30px 30px;
    background: #545F70;
    border-radius: 15px;
    margin-top: 30px;
    min-height: 165px;
}

.bio_user h2 {
    color: #fff;
    font-size: 26px;
    text-align: left;
}

.bio_user p {
    color: #fff;
    font-size: 16px;
    margin-bottom: 0px;
}

@media screen and (max-width:1199px) {
    .nft_inner {
        max-width: calc(100%/3);
        margin-bottom: 20px;
    }

    .george_hermis_user {
        flex-wrap: wrap;
    }

    .hermis_user_right h2 {
        font-size: 22px;
    }

    .height_weight,
    .hermis_bottom {
        flex-wrap: wrap;
        justify-content: center;
    }

    .hermis_bottom span {
        margin-right: 10px;
    }

    .nft_man_inner_partts {
        max-width: calc(100%/2);
    }

    .football_match {
        flex-wrap: wrap;
    }
}

@media screen and (max-width:991px) {
    .bio_user {
        margin-bottom: 30px;
    }
}

@media screen and (max-width:575px) {
    .user_details_top_partts {
        padding: 30px;
    }
}

@media screen and (max-width:420px) {
    .nft_inner {
        max-width: calc(100%/2);
        margin-bottom: 10px;
    }

    .header_date_and_time {
        flex-wrap: wrap;
        justify-content: center;
    }
}



/***** dip design start *******/
.nft_man_partts.nft_man_collectables {
    gap: 15px 5px;
}

.nft_man_partts.nft_man_collectables .nft_man_inner_partts {
    max-width: inherit !important;
    flex: 0 0 49%;
    margin: 0;

    background: #2833426e;
    padding: 0;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding-right: 15px;
}

.nft_man_partts.nft_man_collectables .nft_man_inner_partts .nft_mansss img {
    width: auto;
    /* max-width: 60px; */
    height: 60px;
    max-height: 60px;
    border-radius: 6px;
}

.nft_man_partts.nft_man_collectables .nft_man_inner_partts .nft_mansss{
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: auto;
    flex: 0 1 auto;
}

.nft_man_partts.nft_man_collectables .nft_man_inner_partts .nft_manss_heading {
    flex: 1 1 auto;
}


.common_img {max-width: 100px; height: 30px; overflow: hidden; margin-right: -10px;} 
.common_img img {float: left; max-height: 100%;}
/***** dip design end *******/