.left_panel {
    max-width: 20%;
    width: 100%;
    background: #283342;
    position: relative;
    flex: 0 0 auto;
    padding-bottom: 60px;
}
.pro-arrow-wrapper span {
    position: relative;
    border-width: 0px 0px 0px 0px !important;
    transform: none !important;
}
.pro-arrow-wrapper span:after {
    content: "+";
    position: absolute;
    color: white;
    font-size: 24px;
    right: 0px;
    border: 0px;
    top: -18px;
}
.pro-menu-item.pro-sub-menu.open .pro-arrow-wrapper span:after {
    content: "-";
    position: absolute;
    color: white;
    font-size: 24px;
    right: 0px;
    border: 0px;
    top: -18px;
}

.left_panel_header {
    min-height: 60px;
    background: #1d242e;
    text-align: center;
}

.left_panel_header img {
    padding-top: 15px;
}

.left_panel_admin_parts {
    padding: 36px 28px;
    display: flex;
    justify-content: space-between;
}
.admin_left_box {
    width: 42px;
    height: 42px;
    position: relative;
}
.admin_left_box img {
    width: 42px;
    height: 42px;
    border-radius: 6px;
    object-fit: cover;
}

.user_online {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    background: #2ed47a;
    border: 2px solid #2e3744;
    position: absolute;
    bottom: 5px;
    right: -10px;
}

.admin_left {
    display: flex;
    align-items: center;
}

.admin_left h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    margin-left: 20px;
    margin-bottom: 0px;
}

.admin_right .dropdown-toggle:after {
    content: none;
}

.my_prf_inner .nav_dash {
    display: block;
}

.my_prf_inner .nav_dash li a {
    padding: 12px 35px;
    display: flex;
    align-items: center;
    transition: all 0.1s ease-in-out;
}
.my_prf_inner .nav_dash li a .dasg__imgg {
    display: inline-block;
    width: 16px;
    margin-left: 0;
}
.my_prf_inner .nav_dash li a span {
    font-weight: 400;
    font-size: 16px;
    color: #fff !important;
    margin-left: 20px;
}

.nav_dash li a.active,
.nav_dash li a:hover {
    background: linear-gradient(90deg, #01c1c1 0%, rgba(1, 193, 193, 0) 100%);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
    transition: all 0.1s ease-in-out;
}

.nav_dash li .logout_section.active,
.nav_dash li .logout_section:hover {
    background: linear-gradient(
        90deg,
        #f00 0%,
        rgba(1, 193, 193, 0) 100%
    ) !important;
    color: white;
    transition: all 0.1s ease-in-out;
}

.logoutIcon {
    fill: red;
    height: 32px;
}

.nav_dash li .logout_section.active .logoutIcon {
    fill: white;
}

.logoutTxt {
    color: red !important;
    font-size: 20px !important;
    font-weight: 700 !important;
}

.nav_dash li a.active,
.nav_dash li .logout_section:hover .logoutTxt,
.nav_dash li a.active,
.nav_dash li .logout_section:hover svg {
    color: white !important;
    fill: white !important;
}

.powered_by {
    position: absolute;
    width: 100%;
    height: 34px;
    background: #1d242e;
    left: 0;
    bottom: 0;
    font-weight: 400;
    font-size: 10px;
    line-height: 34px;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
}

/***** Dip Start ******/
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    position: relative;
    display: flex;
    align-items: center;
    /* padding: 8px 35px 8px 20px; */
    cursor: pointer;
    padding: 12px 35px;
    position: relative;
}

.pro-sidebar .pro-menu .react-slidedown .pro-menu-item > .pro-inner-item:after {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    top: 100%;
    background: rgb(101, 113, 131);
    background: linear-gradient(
        90deg,
        rgba(101, 113, 131, 1) 0%,
        rgba(101, 113, 131, 0) 100%
    );
}

.pro-sidebar
    .pro-menu
    .react-slidedown
    .pro-menu-item:nth-child(1)
    > .pro-inner-item:before {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 100%;
    background: rgb(101, 113, 131);
    background: linear-gradient(
        90deg,
        rgba(101, 113, 131, 1) 0%,
        rgba(101, 113, 131, 0) 100%
    );
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 20px;
}
.pro-sidebar
    .pro-menu
    .pro-menu-item.pro-sub-menu
    > .pro-inner-item
    > .pro-arrow-wrapper {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.pro-sidebar
    .pro-menu
    .pro-menu-item.pro-sub-menu
    > .pro-inner-item
    > .pro-arrow-wrapper
    .pro-arrow {
    display: inline-block;
    border-style: solid;
    border-color: #adadad;
    border-width: 0 2px 2px 0;
    padding: 2.5px;
    vertical-align: middle;
    transition: transform 0.3s;
    transform: rotate(-45deg);
}

.pro-sidebar
    .pro-menu
    .pro-menu-item.pro-sub-menu.open
    > .pro-inner-item
    > .pro-arrow-wrapper
    .pro-arrow {
    transform: rotate(45deg);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    padding-left: 24px;
}
.react-slidedown.closed {
    display: none;
}
.react-slidedown {
    height: 0;
    transition-property: none;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}
.pro-sidebar .pro-menu .pro-menu-item {
    font-size: 16px;
    color: #fff;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.pro-sidebar
    .pro-menu
    .pro-menu-item.pro-sub-menu
    .pro-inner-list-item
    .pro-inner-item {
    padding: 8px 30px 8px 25px;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
    flex-grow: 1;
    flex-shrink: 1;
}

.my_prf_inner .nav_dash li a {
    padding: 12px 35px;
    display: flex;
    align-items: center;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content a {
    padding: 0;
}
.pro-sidebar
    .pro-menu
    .pro-menu-item
    > .pro-inner-item
    > .pro-item-content
    a
    span {
    margin-left: 0;
}

/***** Dip end ******/

@media screen and (max-width: 991px) {
    .my_prf_inner .nav_dash li a span {
        font-size: 13px;
    }
}

@media screen and (max-width: 767px) {
    .left_panel {
        max-width: 100%;
    }
}
