.nft_user_top_partts {
  padding: 40px 60px;
}

/* .user_management_list{
  background: none !important;
} */

.total_users_right.product-list-alignment{
  width: auto;
  max-width: auto;
  display: inline-block;
  margin: 0px;
  padding: 0px;
}

.add_button_set_align{
  background: #01C1C1;
  border-radius: 30px;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  border: 0px;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  display: inline-block;
  min-width: 180px;
}

.add-button-align{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  /* padding: 0px 60px 0px 0px; */
}

.errorMessage {
  color: #f15959;
  text-transform: capitalize;
  font-size: initial;
}

.nft_user_top_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nft_user_top_heading h2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
}

.new_created_prf {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.created_inner_prf {
  padding: 0 5px;
  max-width: calc(100% / 3);
  width: 100%;
}
.nft_loading {
  max-width: 50%;
  width: 100%;
}
.created_inner_inner_prf {
  background: #75859d;
  border-radius: 6px;
  padding: 30px 0 30px 25px;
  margin-bottom: 15px;
}

.nft_profiless {
  margin-top: 30px;
}

.created_inner_inner_prf h2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #c2cddd;
  margin-bottom: 10px;
}

.created_inner_inner_prf label {
  font-weight: 400;
  font-size: 48px;
  line-height: 59px;
  color: #2bfafa;
  margin-bottom: 0px;
}

.piechart_js {
  padding: 25px 35px;
  background: #495a71;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nft_pie {
  max-width: 60%;
  width: 100%;
}

.nft_pie_right {
  max-width: 40%;
  width: 100%;
}

.nft_iner_pie {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  /* justify-content: space-between; */
}
.rounded_pie {
  margin-right: 10px;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
}
.rounded_pie.color1pie {
  background: #30ffa8;
}
.rounded_pie.color2pie {
  background: #ff4f4f;
}

.rounded_pie.color3pie {
  background: #37abff;
}

.rounded_pie.color4pie {
  background: #eedd44;
}
.inner_pie_text h2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 5px;
  margin-bottom: 6px;
  color: rgba(255, 255, 255, 0.6);
}

.inner_pie_text label {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 0;
}

.man_priceeee {
  font-weight: 400;
  font-size: 15px;
  line-height: 5px;
  color: #25f8f8;
}



/******** Dip start ********/
.levelsetting.manage_product {
  padding: 10px 20px;
}

.levelsetting .nav-tabs {
  margin-left: -20px;
  margin-right: -20px;
  border: none;
}

.nav-tabs .nav-item {
  padding: 0 1px;
}

.nav-tabs .nav-link {
border: none;  
border-bottom: 4px solid #878F9B;
font-size: 18px;
font-weight: 400;
color:#fff;
}

.nav-tabs .nav-link span img {vertical-align: baseline;}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background: transparent; 
  border: none;
  border-bottom: 4px solid #01C1C1;
  color:#fff
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover { border: none; border-bottom: 4px solid #01C1C1; outline: none !important;}

.add_attribute { border-top-right-radius: 0; border-bottom-right-radius: 0; }
.add_button {background-color: #01C1C1; color:#fff; border: none; border-radius: 5px; font-size: 18px; border-top-left-radius: 0; border-bottom-left-radius: 0;}

.ReactTags__tags {display: flex; flex-wrap: wrap; margin: 15px 0; }

.rdt_Table input[type="file"] {
  display: none;
}
.custom-file-upload {
  width: 109px;
height: 30px;
background: #75859D;
border-radius: 3px;
font-family: Metrophobic;
font-Weight:400;
font-Size:14px;
Line-height:30.26px;
text-align: center;
}

.tag {background: #464F5C; padding: 3px 15px; color:#B8C0CA; font-family: Metrophobic;
  font-Weight:400;
  font-Size:14px;
  border-radius: 3px;
}

.color_box {width: 30px; height:30px; border-radius: 50px; margin: 0 10px;}
.color_box.active {border: 3px solid rgba(222,222,222,1); position: relative;}
.color_box.active:after {
  content: "✓";     
  position: absolute;
  color: #fff;
  font-size: 23px;
  line-height: 25px;
  width: 18px;
  left: 50%;
  margin-left: -9px;
}

.color_box_wrapper { flex-wrap: wrap; display: flex; }

/******** Dip start ********/


@media screen and (max-width: 480px) {
  .created_inner_prf {
    max-width: calc(100% / 1);
  }
  .created_inner_inner_prf {
    padding: 15px 0 15px 10px;
  }
  .created_inner_inner_prf label {
    font-size: 32px;
  }
  .piechart_js {
    padding: 15px;
    flex-wrap: wrap;
  }
  .nft_pie {
    max-width: 100%;
  }
  .nft_pie_right {
    max-width: 100%;
    margin-top: 20px;
  }
  .nft_user_top_partts {
    padding: 20px 30px;
  }
}

.man_text.story_text.allign{
  margin-left: -3px;
}

.add_button_set_align.link_add_product{
  background: #01C1C1;
  border-radius: 30px;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  border: 0px;
  font-weight: 400;
  margin-top: 50px;
  font-size: 15px;
  color: #fff;
  margin-top: 30px;
}
