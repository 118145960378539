.dash_partts.add_banner_background{
    background-color: #545F70;
    margin: 0px 40px;
    padding: 40px;
    color: #ffff;
    border-radius: 15px;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Choose File';
    display: inline-block;
    background: #75859D;
    border-radius: 3px;
    padding: 5px 8px ;
    margin: 20px -80px 40px 70px;
    font-family: "Metrophobic";
    cursor: pointer;
    color: rgb(255, 255, 255);
  }
  .custom-file-input:hover::before {
    background: #8c9eb8;
  }