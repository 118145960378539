/* .overall-wrap{
    padding: 40px 0;
}
.main-paragraph-wrap, .local-paragraph-wrap, .statement{
    margin: 0 0 60px 0;
    color: white;
    padding: 0 40px;
}
.main-paragraph-wrap h2{
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 15px;
    color: white;
}
.main-paragraph-wrap p{
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
}
.hypeu_header_logo{
    margin-top: 5px;
}
.header_texfieldt_box{
    position: relative;
    left: -40px;
}
.local-paragraph-wrap p{
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: white;
    margin-bottom: 20px;
}
.local-paragraph-wrap-li{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: white;
    margin-bottom: 20px;
}
.local-paragraph-wrap ul li{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: white;
    margin-bottom: 20px;
}
.list-margin{
    margin-top: -10px;
}
.agreement h4{
    margin-top: 30px;
}
.collecting-heading{
    color: white !important;
    font-size: 19px;
    font-weight: 7005
}
.list-margin.margin-left{
    margin-left: 18px;
}
ul:nth-of-type(1) {
    list-style-type: disc;
}
ul:nth-of-type(2) {
    list-style-type: disc;
} */


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  /* vietnamese */
  @font-face {
    font-family: "Metrophobic";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/public/fonts/metropublic.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
      U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  
  body {
    font-family: "Metrophobic", sans-serif !important;
  }
  
  h1 {
    font-family: Raleway, Arial, Helvetica, sans-serif;
  }
  .red {
    color: #ff4122 !important;
  }
  
  /* navbar css start */
  
  .hypeu_nav {
    background-color: #384659;
    padding: 20px 60px;
  }
  .header_search .form-inline {
    width: 80%;
    position: relative;
    display: inherit;
  }
  .header_search input {
    background: rgba(227, 235, 243, 0.5);
    border-radius: 4px;
    width: 100% !important;
    height: 38px;
    color: rgba(255, 255, 255, 0.804) !important;
    font-size: 17px;
  }
  .header_search button {
    position: absolute;
    top: 0;
    right: 0;
  }
  .header_date_and_time {
    display: flex;
    justify-content: flex-end;
  }
  .header_notification a,
  .header_logout a {
    padding: 7px 20px;
  }
  .header_notification a:hover,
  .header_logout a:hover {
    background-color: #01c1c1;
    border-radius: 5px;
  }
  .header_date span {
    color: #fff;
  }
  .header_date {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hypu_time {
    margin-left: 8px;
  }
  .header_search form .form-control:focus {
    background-color: rgba(227, 235, 243, 0.644);
    border-color: #fff;
    outline: 0;
    box-shadow: none;
  }
  
  /* navbar css end */
  
  /* about_us banner css start */
  
  .hypeu_about_banner {
    background-image: url(/public/images/about_banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 350px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hypeu_about_banner:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .hypeu_about_banner h1 {
    font-size: 65px;
    font-weight: 900;
    color: #fff;
    z-index: 9;
    margin-top: 2.5rem;
  }
  
  /* about_us banner css end */
  
  /*  Body content css start */
  
  .main_cntn {
    background-color: #455060;
    padding: 55px 60px 45px;
    color: #fff;
  }
  .main_cntn h2 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 2.5rem;
    margin-top: 20px;
  }
  .cntn_sec p {
    font-size: 18px;
    line-height: 26px;
  }
  .legal {
    margin-bottom: 60px;
  }
  .legal h2 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .statement h2 {
    color: red;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 15px;
    font-family: "Metrophobic", sans-serif !important;
  }
  .objectives {
    margin-top: 2.5rem;
  }
  .objectives h4 {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 12px;
  }
  .objectives ul li h5 {
    font-size: 19px;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .objectives ul {
    padding-left: 2rem;
    list-style-type: disc;
  }
  ul li::marker {
    color: white;
  }
  .statement p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 20px;
  }
  .statement {
    margin-bottom: 60px;
  }
  .hypeu_footer .container_fluid .ft_cntn ul {
    list-style-type: none;
  }
  ol li.security_list::marker {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
  }
  .statement ol {
    padding-left: 2rem;
  }
  .security_list h3 {
    font-size: 20px;
    font-weight: 600;
    color: white;
  }
  .security_list p span {
    display: block;
    font-size: 18px;
  }
  .security_list ul {
    padding-left: 2rem;
    list-style-type: disc;
  }
  .security_list ul li::marker {
    color: #fff;
  }
  .security_list ul li {
    font-weight: 600;
  }
  ol li.collecting-heading::marker {
    font-size: 19px;
    color: #ff4122;
    font-weight: 700;
  }
  ol li.collecting-heading h4 {
    font-size: 19px;
    font-weight: 700;
  }
  ol li.collecting-heading b {
    display: block;
    padding: 20px 0;
    font-size: 18px;
  }
  .scope-para span {
    font-size: 20px;
    font-weight: 700;
    display: block;
  }
  .list-bold {
    display: block;
    padding: 20px 0;
    font-size: 18px;
    color: #ff4122;
  }
  .left-margin li.list-h5::marker {
    color: #ff4122;
    font-size: 20px;
  }
  .left-margin li::marker {
    color: #ff4122;
    font-size: 20px;
  }
  ol.left-margin li.list-h5 b {
    color: #ff4122;
    font-size: 20px !important;
    display: block;
    padding: 20px 0;
  }
  ol.left-margin b {
    font-size: 20px !important;
    display: block;
    padding: 20px 0;
  }
  
  ul.unordered-list {
    padding-left: 2rem;
    list-style-type: disc;
  }
  ul.unordered-list li::marker {
    font-size: 19px;
    color: #fff;
  }
  ul.unordered-list li {
    font-size: 19px;
    color: #fff;
    font-weight: normal;
    line-height: 1.8;
  }
  p.mt {
    margin-top: 1.2rem;
  }
  ol.personal-data-font li {
    font-size: 17px;
    font-weight: 500;
    line-height: 1.6;
  }
  .terms_main_cntn ol {
    padding-left: 2rem;
  }
  .local-paragraph-wrap-li {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 20px;
  }
  li.collecting-heading h4 {
    font-size: 22px !important;
    margin: 30px 0 11px;
  }
  li.collecting-heading p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 20px;
  }
  li.collecting-heading li.list-margin {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 20px;
  }
  li.collecting-heading ul li.list-margin::marker {
    font-size: 16px;
    color: #fff;
  }
  /*  Body content css end */
  
  /* footer css start */
  
  .hypeu_footer {
    background: #283342;
    padding: 5px 60px;
  }
  .ft_cntn {
    justify-content: space-between;
    align-items: center;
  }
  .ft_cntn p {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
    color: rgba(255, 255, 255, 0.7);
  }
  .ft_cntn ul {
    padding: 0;
    margin-bottom: 0;
  }
  .ft_cntn ul a {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    margin-left: 10px;
  }
  
  .ft_cntn ul a:hover {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #40a9ff;
    text-decoration: none;
    margin-left: 10px;
  }
  /* footer css end */
  
  /* privacy policy banner css start */
  
  .hypeu_privacy_banner {
    background-image: url(/public/images/privacy_policy.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 350px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hypeu_privacy_banner:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .hypeu_privacy_banner h1 {
    font-size: 65px;
    font-weight: 900;
    color: #fff;
    z-index: 9;
    margin-top: 2.5rem;
  }
  
  /* privacy policy banner css end */
  
  /* terms and conditions banner css start */
  
  .hypeu_terms_banner {
    background-image: url(/public/images/terms_and_condition.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 350px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hypeu_terms_banner:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .hypeu_terms_banner h1 {
    font-size: 65px;
    font-weight: 900;
    color: #fff;
    z-index: 9;
    margin-top: 2.5rem;
  }
  
  /* terms and conditions banner css end */
  
  @media only screen and (max-width: 991px) {
    .hypeu_nav {
      padding: 20px 30px;
    }
    .hypeu_footer {
      padding: 5px 30px;
    }
  }
  
  @media only screen and (max-width: 968px) {
    .header_notification {
      margin-right: 6px !important;
    }
  }
  @media only screen and (max-width: 767px) {
    .header_logo {
      /* text-align: center; */
      margin-bottom: 15px;
    }
    .header_search {
      margin-bottom: 15px;
    }
    .header_search .form-inline {
      margin: 0 auto;
    }
    .header_date_and_time {
      justify-content: center;
    }
    .hypeu_terms_banner h1,
    .hypeu_privacy_banner h1,
    .hypeu_about_banner h1 {
      font-size: 45px;
      text-align: center;
    }
  }
  @media only screen and (max-width: 575px) {
    .hypeu_nav {
      padding: 15px 15px;
    }
    .hypeu_footer {
      padding: 5px 15px;
    }
    .ft_cntn {
      flex-direction: column;
    }
    .ft_cntn p {
      margin-bottom: 5px;
    }
    .header_search .form-inline {
      width: 100%;
    }
    .header_search button {
      margin: 0 !important;
    }
    .hypeu_terms_banner,
    .hypeu_privacy_banner,
    .hypeu_about_banner {
      height: 200px;
    }
    .hypeu_terms_banner h1,
    .hypeu_privacy_banner h1,
    .hypeu_about_banner h1 {
      font-size: 30px;
    }
    .main_cntn {
      padding: 55px 15px 45px;
    }
  }
  @media only screen and (max-width: 425px) {
    .header_date_and_time {
      justify-content: space-between;
    }
    .header_notification a,
    .header_logout a {
      padding: 7px 10px;
    }
    .header_date img {
      width: 16px;
    }
    .header_date span {
      font-size: 15px;
    }
  }
  
