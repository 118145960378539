@import url("https://fonts.googleapis.com/css2?family=Metrophobic&display=swap");
body {
  padding: 0px;
  margin: 0px;
  background-color: #455060 !important;
  font-family: "Metrophobic", sans-serif !important;
}

.ant-message {
  z-index: 999999 !important;

}
.user_partts{
  position:relative;
  height:100%;
  }
  .footer_partttss{
  position:absolute;
  width:80%;
  bottom:0;
  }

.forgotPass .modal-content{
  top: 80px !important;
}
/* .ant-message-notice {
  text-align: right !important;
} */
/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.user_partts{
  position:relative;
  height:100%;
  }
  .footer_partttss{
  position:absolute;
  width:80%;
  bottom:0;
  }