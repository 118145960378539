.nft_user_top_partts {
  padding: 40px 60px;
}

.nft_user_top_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nft_user_top_heading h2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
}

.new_created_prf {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.created_inner_prf {
  padding: 0 5px;
  max-width: calc(100% / 3);
  width: 100%;
}
.nft_loading {
  max-width: 50%;
  width: 100%;
}
.created_inner_inner_prf {
  background: #75859d;
  border-radius: 6px;
  padding: 30px 0 30px 25px;
  margin-bottom: 15px;
  transition: all 0.3s ease-in-out;
}
.created_inner_inner_prf:hover{
  /* box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
  border: 1px solid #01C1C1;
  transition: all 0.3s ease-in-out; */
  box-shadow: 0px 0px 15px rgb(0 0 0 / 40%);
  background: linear-gradient(90deg, #01C1C1 0%, rgba(1, 193, 193, 0) 100%);
  transition: all 0.1s ease-in-out;
  color: white !important;
  border-radius: 6px;
}
.man_pricee {
  font-weight: 400;
  font-size: 14px;
  line-height: 5px;
}

.nft_profiless {
  margin-top: 4.6rem !important;
}

.created_inner_inner_prf h2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #c2cddd;
  margin-bottom: 10px;
}

.created_inner_inner_prf label {
  font-weight: 400;
  font-size: 48px;
  line-height: 59px;
  color: #2bfafa;
  margin-bottom: 0px;
}

.piechart_js {
  padding: 25px 35px;
  background: #495a71;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nft_pie {
  max-width: 60%;
  width: 100%;
}

.nft_pie_right {
  max-width: 40%;
  width: 100%;
}

.nft_iner_pie {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  /* justify-content: space-between; */
}
.rounded_pie {
  margin-right: 10px;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
}
.rounded_pie.color1pie {
  background: #30ffa8;
}
.rounded_pie.color2pie {
  background: #ff4f4f;
}

.rounded_pie.color3pie {
  background: #37abff;
}

.rounded_pie.color4pie {
  background: #eedd44;
}
.inner_pie_text h2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px !important;
  margin-bottom: 6px;
  color: rgba(255, 255, 255, 0.6);
}

.inner_pie_text label {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 0;
}

.man_priceeee {
  font-weight: 400;
  font-size: 10px;
  line-height: 5px;
  color: #25f8f8;
}
@media screen and (max-width: 480px) {
  .created_inner_prf {
    max-width: calc(100% / 1);
  }
  .created_inner_inner_prf {
    padding: 15px 0 15px 10px;
  }
  .created_inner_inner_prf label {
    font-size: 32px;
  }
  .piechart_js {
    padding: 15px;
    flex-wrap: wrap;
  }
  .nft_pie {
    max-width: 100%;
  }
  .nft_pie_right {
    max-width: 100%;
    margin-top: 20px;
  }
  .nft_user_top_partts {
    padding: 20px 30px;
  }
}
