.manage_heading_search.style{
    margin-top: 2rem;
}

.man_text.width{
    width: 300px;
}

.transaction-list {
    padding: 30px 60px;
}