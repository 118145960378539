.right_panel_container {
    margin-top: 40px;
    padding: 26px 60px;
}
.frlist{
    padding: 20px 61px 20px 20px !important;
    max-height:"150px" !important;
}
.headerCC.Contributesr{
    border-radius: 10px 10px 0px 0px;
    padding-left: 47px;
    padding-right: 58px;
    margin-top: -17px;
    padding-bottom: 10px;
    margin-left: -10px;
    margin-right: -10px;
  }
  
  .resume-date-border{
    background: transparent;
    color: white;
    border: none;
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px 20px 5px 20px;
  }
  
  .resume-date-border:hover{
    color: #FFFFFF;
    border: none;
    font-weight: bold;
    border: 1px solid white;
    border-radius: 5px;
  }
  
  .resume-date{
    background: transparent;
    color: white;
    border: none;
  }
  
  .resume-date:hover{
    color: #FFFFFF;
    border: none;
    font-weight: bold;
  }

  .headerCC{
    background: #3D4857;
    border-radius: 0px 0px 15px 15px;
    padding: 17px 30px;
    display: flex;
    justify-content: space-between;
}

.headerCC span{
    font-weight: 400;
    font-size: 14px;
    /* line-height: 14px; */
    color: #FFFFFF;
    display: inline-block;
    margin-left: 10px;
}
