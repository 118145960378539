.s_details_left{
    padding: 20px 20px 20px 20px;
    background: #545F70;
    border-radius: 15px 15px 15px 15px;
    
}
.s_details_left_inner{
    display: flex;
    /* flex-wrap: wrap; */
}

.sdetails_left_img{
   
}
.sdetails_left_img{
    max-width: 400px;
    width: 100%;
    max-height: 400px;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2833426e;
}

.sdetails_left_img img{
    max-width: 100%;
    max-height: 400px;
    object-fit: cover;
}
.s_details_right{
    margin-left: 20px;
    max-width: 60%;
    width: 100%;
}

.s_details_right h3{
    font-size: 22px;
    color: #fff;
    margin-bottom: 10px;
}

.s_details_right p{
    font-size: 16px;
    color: #fff;
    margin-bottom: 0px;
}

.created_date{
    margin-top: 20px;
}
/* .created_date p{
    font-size: 16px !important;
    color: #fff;
} */

.created_date p span{
    color: #fff;
    font-size: 18px;
    display: inline-block;
    max-width: 200px;
    width: 100%;
}
.created_date p{
    font-size: 16px;
}
.another_font p{
    font-size: 17px !important;
}
.reaction_part{
    display: flex;
    flex-wrap: wrap;
    color: #fff;
}

.react_heading{
    max-width: 200px;
    width: 100%;
}


.reaction_part .like_part span{
    color: #fff;
    font-size: 18px;
}

.s_details_right_parttts{
    padding: 20px 20px 20px 20px;
    background: #545F70;
    border-radius: 15px 15px 15px 15px;
}

.storyfield_part label{
    color: #fff;
}

@media screen and (max-width:991px){
    .sdetails_left_img{
        max-width: 300px;
        height: 300px;
    }
}

@media screen and (max-width:575px){
    .s_details_left_inner{
        flex-wrap: wrap;
    }
    .sdetails_left_img{
        max-width: 70%;
        height: 250px;
        margin: 0 auto;
    }
    .s_details_right{
        margin-left: 0px;
        margin-top: 25px;
        max-width: 100%;
    }
}

@media screen and (max-width:420px){
    .sdetails_left_img{
        max-width: 100%;
    }
    .created_date p span, .react_heading{
        max-width: 150px;
    }
    .reaction_part{
        flex-wrap: nowrap;
    }
}