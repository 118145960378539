.user_text_area {
    background: #556274;
    border-radius: 10px;
    border: 0px;
    color: #BAC0C9;
}

.user_text_area:focus{
    background: #556274;
    color: #BAC0C9;
}

.gender_partts{
    border: 1px solid #6B7585;
    border-radius: 10px;
    padding: 20px;
}

.settings_partts h2{
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 40px;
}

.settings_inner{
    border: 1px solid #6B7585;
    border-radius: 10px;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.settings_inner_left{
    display: flex;
    flex-wrap: wrap;
    max-width: 70%;
    width: 100%;
    align-items: center;
}
.settings_inner_right{
    max-width: 30%;
    width: 100%;
}

.settings_user{
    width: 103px;
    height: 103px;
    /* overflow: hidden; */
    position: relative;    
}

.settings_user img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.edit_field{
    position: absolute;
    right: -10px;
    bottom:-10px;
}
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .upload-btn-wrapper .btn {
    background: #01C1C1;
    width: 23px;
    height: 23px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0px;
  }
  .upload-btn-wrapper .btn img{
    width: auto;
    height: auto;
  } 
  .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

.user_name_edit h3{
    font-weight: 400;
    font-size: 22px;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.user_name_edit{
    margin-left: 30px;
}

.user_name_edit .user_iconnc{
    margin-left: 10px;
}

.user_name_edit h6{
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: #FFFFFF;
}

.user_badge_partts{
    display: flex;
    flex-wrap: wrap;
}

.user_badge_partts span{
    margin: 0 5px;
}
.user_badge_partts .edit_buttonn{
    padding: 0px 20px;
    height: 30px;
    line-height: 25px;
    background: #455060;
    border: 1px solid #657080;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    margin: 0 5px;
}

.settings_inner_right h3{
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    margin-right: 10px;
    margin-bottom: 0px;
}

.settings_inner_right{
    display: flex;
    align-items: center;
}

.pdetails{
    margin-top: 30px;
}

.pdetails_left h3, .text_password h3{
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.pdetails_left_inner, .change_pass_inner{
    background: #455060;
    border: 1px solid #6B7585;
    border-radius: 10px;
    padding: 20px;
}
.gender_partts{
    border: 1px solid #6B7585;
    border-radius: 10px;
    padding: 14px 20px;
}
.edit_text_fielddds label{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.edit_text_fielddds .text_field_parttss{
    background: #556274;
    border-radius: 10px;
    height: 56px;
    border: 0px;
    color: #BAC0C9;
}
.edit_text_fielddds .text_field_parttss::placeholder{
    color: #BAC0C9;
}

.custom_radio{
    display: flex;
    align-items: center;
}

.custom_radio p{
    margin: 0px 10px;

}

.custom_radio [type="radio"]:checked,
.custom_radio [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.custom_radio [type="radio"]:checked + label,
.custom_radio [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
}
.custom_radio [type="radio"]:checked + label:before,
.custom_radio [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #455060;
    border: 2px solid #01C1C1;
}
.custom_radio [type="radio"]:checked + label:after,
.custom_radio [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #01C1C1;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.custom_radio [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.custom_radio [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.save_changes .save_changes_but{
    display: block;
    width: 100%;
    background: #01C1C1;
    border-radius: 10px;
    height: 50px;
    line-height: 50px;
    border: 0px;
    padding: 0px;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #fff;
    margin-top: 130px;
}
.user_badge_partts span{
    margin-bottom: 20px;
}
.icon_field{
    position: relative;
}
.icon_field .text_field_parttss{
    padding: 0 30px 0 20px;
}
.icon_field .user_iconnc{
    position: absolute;
    right: 10px;
    top: 16px;
}
.text_field_parttss.callCode {
    width:100%;
    padding: 4px;
}
@media screen and (max-width:991px){
    .settings_inner{
        flex-wrap: wrap;
    }
    .settings_inner_left{
        max-width: 100%;
    }
    .settings_inner_right{
        max-width: 100%;
        margin-top: 20px;
    }
}

@media screen and (max-width:767px){
    .text_password{
        margin-top: 30px;
    }
}
@media screen and (max-width:575px){
    .user_name_edit{
        max-width: 100%;
        width: 100%;
        margin-left: 0px;
        margin-top: 30px;
    }

    .user_badge_partts span{
        margin-bottom: 20px;
    }
}