.addnew_badge_partts {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}

.add-frame {
    margin: 2rem 8rem;
    background-color: #283342;
    border-radius: 10px;
    padding: 20px;
    width: 50%;
}

.add-frame_header {
    font-size: 24px;
    color: white;
    padding: 20px 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.uploaded_image {
    width: auto;
    height: 18rem;
    margin: 10px 20px;
    display: block;
    text-align: center;
    /*margin: 20px auto;*/
}

.landscape_image {
    width: 20rem !important;
    height: auto !important;
}

.action_btns-wallet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 220px;
}

.actionButton {
    color: #c8c8ff;
    cursor: pointer;
    font-size: 15px;
}

.framelist_table {
    margin: 0 5rem;
}

.ant-modal-body {
    background-color: #455060;
    color: white;
}

.ant-modal-footer {
    background-color: #455060;
    color: white;
}

.ant-modal-close-x {
    color: white;
}

.fields {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.image-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* width: 70rem; */
    margin: 60px 60px 60px 60px;
    position: relative;
}

.bottom-text {
    position: absolute;
    color: white;
    bottom: 21px;
    left: 18px;
    font-size: 33px;
}

.formfield {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 15px;
    width: 120px;
}

.formfield label {
    color: white;
    font-size: 14px;
}

.formfield input {
    border: 1px solid #838383;
    outline: none;
    width: 120px;
    height: 2rem;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 12px;
}

.image-right button {
    width: 100%;
    margin-top: 18px;
    font-size: 12px;
}

.image_section {
    max-width: 700px;
    width: 100%;
    text-align: center;
    position: relative;
}

.inputfields {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.last_name {
    /*width: 18rem;*/
    margin-left: 1rem;
}

.choose_field {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inputField {
    /*width: 50%;*/
}

.dropdownField {
    width: 40%;
}

.dropdownBtn {
    background: #455060 !important;
    width: 60%;
}

.fontFamily,
.fontFamily button {
    width: 100%;
    margin-top: 0;
}

.fontslist .dropdown-menu.show {
    height: 11rem;
    /* padding: 5px; */
    overflow-y: scroll;
    overflow-x: hidden;
}

.fontslist .dropdown-menu.show a {
    font-size: 14px;
}

.image-right .designButton {
    /*background: #455060 !important;*/
    background-color: rgb(57, 63, 70);
    border-radius: 30px !important;
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    border: 0px;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    display: block;
    cursor: pointer;
    width: 100%;
}

.saveBtn {
    background-color: #0b5ed7 !important;
}

.text_box,
.text_box:focus {
    border: 1px solid #6398ff;
    outline: none;
    width: 120px;
    height: 2rem;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 12px;
}

.image-right {
    max-width: 300px;
    width: 100%;
}

.x_axis_direction,
.y_axis_direction {
    border-top: 1px solid #fff;
    padding-top: 20px;
    margin-top: 20px;
    position: relative;
}

.x_axis_direction span,
.y_axis_direction span {
    position: absolute;
    top: -15px;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    padding: 0 20px;
    background: #455060;
}

.x_axis_direction {
    margin-top: 60px;
}

.y_axis_direction {
    transform: rotate(270deg);
    position: absolute;
    left: 0px;
    top: 20px;
    width: 74%;
    height: 66%;
    z-index: 1;
}

.image-right .inputfields {
    height: 25rem;
    overflow-y: scroll;
}

.image_section img {
    max-width: 40rem;
}

.inputfields .first_name,
.inputfields .last_name {
    height: 100%;
}

.add_max_widthhh {
    display: flex;
    justify-content: space-between;
}

.text_form label {
    color: #fff;
}

.text_form {
    max-width: 220px;
    width: 100%;
    position: relative;
    z-index: 6;
}

.text_form input,
.text_form input:focus {
    color: white;
    border: 1px solid #838383;
    outline: none;
    /* width: 120px; */
    height: 2rem;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 12px;
    background: transparent;
}

.add_max_widthhh {
    max-width: 470px;
    width: 100%;
    margin: 0 auto;
}

.form_submittt {
    border-radius: 30px !important;
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    border: 0px;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    display: block;
    cursor: pointer;
    width: 200px;
    margin: 0 auto;
}

.text_width_btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 470px;
    width: 100%;
    margin: 0 auto;
}

.add-frame.addframe_body {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
}

.add-frame.addframe_body h5 {
    margin-bottom: 20px;
    font-size: 20px;
    /*margin-top: 50px !important;*/
}

.addframe_body_partts {
    padding: 20px 60px;
}

.add_frame_txt.text_box.text_box1 {
    width: 100%;
    font-size: 16px;
}

.choose_filder .inputField {
    max-width: 48%;
    width: 100%;
    margin-bottom: 20px;
}

.choose_filder .dropdownField {
    max-width: 45%;
    width: 100%;
}

.choose_filder .dropdownBtn {
    width: 100%;
}

.fontslist button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fontfamilyTxt {
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
    text-overflow: ellipsis;
    position: relative;
}

.typed_image.typed_image_partts .upload_image .upload_btn_wrapper {
    max-width: 100%;
    display: block;
    width: 100%;
    padding: 0 20px;
}

.typed_image.typed_image_partts .upload_image h4 {
    padding-left: 20px;
}

.typed_image.typed_image_partts .upload_image .upload_btn_wrapper button {
    width: 100%;
    display: block;
}

.typed_image.typed_image_partts {
    height: auto;
}

.frame_image p {
    font-size: 18px;
}

.frame_design_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.select_type_dd .dropdownField {
    margin-right: 4rem;
}

.select_type_dd .dropdownField,
.select_type_dd .dropdownField button {
    width: 14rem !important;
}

.check_blank_card {
    margin: 20px 0;
}

.check_blank_card label {
    color: white;
    font-size: 18px;
    opacity: 1 !important;
}

.check_blank_card input:checked + label:after {
    border: solid #283342;
    border-width: 0 2px 2px 0;
    top: 2px;
    left: 7px;
}

.dropdown-btn button,
.dropdown-btn button:hover,
.dropdown-btn button:active {
    width: 100%;
    background-color: #455060;
    border: 1px solid #6398ff;
}
