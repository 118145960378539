/* .badge_listing_inner_partts{
    margin-bottom: 70px;
} */

.badge_listing_inner_partts .badges_inner_lists{
    margin-top: 10px;
    padding: 10px 10px;
    /* border: 0.1px solid white; */
    /* border-radius: 10px; */
    min-height: 100px;
}


.user_image_badge{
    width: 80px;
    height: 80px;
    margin: 0 auto;
}

.user_image_badge img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
}

.ind_user_badges h2{
    text-align: center;
    margin: 10px 0;
    font-size: 18px;
    color: #fff;
    text-align: center;
}

@media screen and (max-width:575px){
    .dash_part_heading.user_badge_heading{
        display: block;
        text-align: center;
    }
}