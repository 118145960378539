.payout_status_success h5 span {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #2ED47A;
    border: 3px solid white;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.manEyeStyle.view {
    margin-left: 0.6rem;
}

.amount-color {
    color: #00FFFF;
}

.man_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    margin-left: 5px;
    width: 150px
        /* max-width: 70%; width: 100%; */
    ;
}

.state_parttsss.amount-color h2 {
    color: #00FFFF;
}

.payout_status_success h5 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 18px;
    color: #fff;
}

.payout_status_warning h5 span {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #FFF203;
    border: 3px solid white;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.payout_status_warning h5 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 18px;
    color: #fff;
}

.payout_status_danger h5 span {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #f15959;
    border: 3px solid white;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.payout_status_danger h5 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 18px;
    color: #fff;
}





.main_head {
    font-size: 20px;
    border-bottom: 1px solid #ffffff52;
    flex: 0 0 40%;
    margin: 0 5%;
}

label.main_head {
    border: none;
    color: #fff;
    background: #283342;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
}

label.main_head strong {
    color: #fff;
    font-size: 15px;
    line-height: 20px;
}
.state_parttsss {word-wrap: break-word;}