.nft_purchase_det_inner{
    background: #545f70;
    border-radius: 15px;
    padding: 30px;
    height: 100%;
}

.transaction_table_border{
    border: 1px solid white;
    color: white;
    font-size: 18px;
}

.table_head_center{
    display: flex;
    justify-content: center;
    padding: 0.5rem;
}

.transaction_table_style{
    padding: 0.5rem;
    border-bottom: 1px solid white;
}

.align-table-button{
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.seller_inner_info.download_invoice{
    margin-left: 1rem;
}

.nft_purchase_det_inner h2{
    color: #fff;
    font-size: 32px;
    font-weight: 600;
}

.nft_selling_user_image{
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 10px;
}

.nft_selling_user_image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.nft_selling_imageeerrt{
    display: flex;
    justify-content: space-between;
}

.nft_selling_righter_image{
    width: 120px;
}

.nft_sellers_bio{
    margin-top: 25px;
}

.nft_sellers_bio p{
    font-size: 16px;
    color: #fff;
    margin-bottom: 15px;
}
.nft_sellers_bio p span{
    font-size: 20px;
    color: #fff;
    display: inline-block;
    
}

.user_title{
    width: 230px;
    font-weight: 600;
}
.user_nft_name{
    display: inline-block;
}

.kevin_bio.nft_purchase_detailssss {
    padding: 60px 60px 40px 60px;
  }
  
  .kevin_inner_bio_parttss .kevin_inner_bio {
    background: #545f70;
    border-radius: 15px;
    padding: 0px 30px;
    display: flex;
  }
  .kevin_inner_bio_parttss{
    background: #545f70;
    border-radius: 15px;
    padding: 30px 0px 0px 00px;
  }
  .kevin_inner_image {
    width: auto;
    height: 200px;
  }
  
  .kevin_inner_image img {
    width: auto;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
  .kevin_inner_text {
    max-width: 84%;
    width: 100%;
    margin-left: 25px;
  }
  
  .kevin_inner_title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .kevin_inner_title p {
    margin-bottom: 0px;
  }
  .kevin_inner_title p .minted {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
  }
  
  .kevin_inner_title p .devider_minted {
    display: inline-block;
    margin: 0 20px;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
  }
  
  .choice_sign .kevin_number {
    font-weight: 400;
    font-size: 17px;
    color: #fff;
    margin-left: 10px;
  }
  .kevin_inner_heading {
    margin-top: 15px;
  }
  .kevin_inner_heading h3 {
    font-weight: 400;
    font-size: 26px;
    color: #fff;
    margin-bottom: 6px;
  }
  
  .kevin_inner_heading h4 {
    font-weight: 400;
    font-size: 20px;
    color: #fff;
    margin-bottom: 0px;
  }
  
  .kevin_badges {
    display: flex;
    align-items: center;
    margin-top: 14px;
  }
  .kevin_badges ul {
    display: flex;
    align-items: center;
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .kevin_badges ul li {
    list-style: none;
    margin: 0 3px;
  }
  
  .kevin_bio.nft_purchase_detailssss .kevin_common {
    margin-left: 0px;
    margin-top: 12px;
  }
  
  .kevin_inner_para p {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 0px;
  }
.kevin_bio.nft_purchase_detailssss .kevin_inner_image img{
    margin-top: -55px;
}

.seller_details{
    background: #505C6D;
    border-radius: 15px;
    padding: 30px 20px;
}

.seller_inner_details{
    max-width: 754px;
    width: 100%;
    margin: 0 auto;
}

.seller_iner_heading h3{
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 17px;
    color: #fff;
}

.seller_inner_inner{
    background: #464F5C;
    border-radius: 15px;
    padding: 10px 20px;
    margin: 20px -10px 0 -10px;
    display: flex;
    transition: all 0.3s ease-in-out;
}
.seller_inner_inner:hover{
    color: white !important;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 40%);
    background: linear-gradient(90deg, #01C1C1 0%, rgba(1, 193, 193, 0) 100%);
    transition: all 0.1s ease-in-out;
    border-radius: 10px;
}

.seller_inner_inner_left {
    max-width: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.seller_inner_foot{
    display: flex;
    align-items: center;
}
.seller_user_image{
    width: 65px;
    height: 65px;
    border-radius: 100%;
    overflow: hidden;
}
.seller_user_image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.seller_user_textts h3{
    font-weight: 400;
    font-size: 26px;
    color: #fff;
    margin-bottom: 5px;
}

.seller_user_textts h5{
    font-size: 18px;
    color: #fff;
}

.seller_user_textts{
    margin-left: 20px;
    /* max-width: 230px;
    width: 100%; */
}

.jehn{
    padding-left: 30px;
}

.seller_info{
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}

.seller_inner_info{
    max-width: calc(100%/4);
    width: 100%;
    margin-bottom: 30px;
}

.seller_inner_info label{
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    display: block;
    color: #FFFFFF;
}

.seller_inner_info h3{
    font-size: 18px;
    color: #fff;
    margin-top: 3px;
    word-break: break-word;
}

.download_in a{
    background: #01C1C1;
    border-radius: 5px;
    height: 46px;
    display: block;
    color: #fff;
    line-height: 46px;
    width: 90%;
    text-align: center;
    font-weight: 400;
font-size: 18px;
}

@media screen and (max-width:991px){
    .seller_inner_info{
        max-width: calc(100%/3);
    }
    /* .seller_inner_inner{
        display: block;
        text-align: center;
    }
    .seller_inner_inner_left{
        max-width: 100%;
        margin-bottom: 25px;
    }
    .jehn {
        padding-left: 0px;
    } */
    .seller_user_textts h3{
        font-size: 14px;
    }
    .seller_user_textts h5{
        font-size: 12px;
    }
    .seller_user_image{
        width: 40px;
        height: 40px;
    }

    .seller_inner_info h3{
        font-size: 16px;
    }

    .download_in a{
        font-size: 14px;
    }
}

@media screen and (max-width:575px){
    .kevin_bio.nft_purchase_detailssss{
        padding: 20px;
    }
    .seller_inner_info{
        max-width: calc(100%/2);
    }

    .seller_iner_heading{
        display: none;
    }

    .seller_inner_inner{
        flex-wrap: wrap;
        display: block;
    }

    .seller_inner_inner_left{
        max-width: 100%;
        margin-bottom: 25px;
        display: block;
    }

    .jehn{
        padding-left: 0px;
    }

    .seller_inner_arrow img{
        transform: rotate(90deg);
        margin-top: 30px;
    }
    .kevin_inner_bio_parttss .kevin_inner_bio{
        padding: 0 20px 30px 20px;
    }
    .kevin_bio.nft_purchase_detailssss .kevin_inner_image img{
        margin-top: 0px;
    }
}

@media screen and (max-width:420px){
    .seller_inner_info{
        max-width: calc(100%/1);
    }
    .kevin_inner_text{
        margin-left: 0px;
    }
}