.offer_adjust{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    margin-left: 6px;
    width: 100%;
}
.offer_price{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    margin-left: 8px;
    width: 100%;
}