.usermaageleft.circularbar {
  margin-top: 5px;
  /* margin-left: 3px; */
  background: #545F70;
  padding-left: 28px;
  padding-bottom: 30px;
  padding-right: 30px;
  border-radius: 10px;
  position: relative;
}
.font_design{
    color:#2BFAFA
}
.hermis_top.fix_design{
    margin-top: 5px;
    background: #545F70;
    padding-bottom: 22px;
    border-radius: 10px;
    position: relative;
}

.state_parttsss strong {font-weight: bold; color: #fff; font-size: 18px;}

.manage_heading_search h5{
  color: white;
}

.usermaageleft .dognut_partts{
  margin-top: 14px;
}