.badges_list_heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}

.badge_form_text{
    color: white !important;
}

.badges_list_heading h3{
    font-weight: 400;
    font-size: 20px;
    color: #fff;
}

.addnew_badge_partts .new_badge1{
    background: #01C1C1;
    border-radius: 30px;
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    border: 0px;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    display: inline-block;
    margin-left: 20px;
}

.addnew_badge_partts .new_badge2{
    background: #F84141;
    border-radius: 30px;
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    border: 0px;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    display: inline-block;
    margin-left: 20px;
}
.badges_inner_lists{
    margin-top: 30px;
    min-height: 400px;
}

.badges_inner_lists ul{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
    padding-left: 0px;
}

.badges_inner_lists ul li{
    padding: 6px 3px;
    list-style: none;
    /* width: 68px;
    height: 68px; */
}

.badge_listtting{
    width: 68px;
    height: 68px;
    overflow: hidden;
}
.badge_listtting img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.man_user.story_user.division_image{
    width: 40px;
    height: 40px;
    margin-right: 0px;
}

.man_text.story_text.division_text h4{
    margin-bottom: 0px;
}
.man_text.story_text.division_text h4 a{
    color: #fff;
    font-size: 14px;
}
.man_email.division_email{
    font-size: 14px;
    color: #fff;
}
.manage_table.division_table{
    background: #545F70;
    border-radius: 10px;
}
.two_image_upload{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.addnew_badge_body{
    padding: 0 70px;
}
.left_image_upload{
    text-align: center;
}
.left_image_upload h2{
    font-size: 24px;
    margin-bottom: 20px;
    color: white !important;
}

.upload_btn_wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .upload_btn_wrapper .btn {
    background: #01C1C1;
    border-radius: 30px;
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    border: 0px;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    display: inline-block;
    cursor: pointer;
  }
  
.upload_btn_wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

.choose_field label{
    display: block;
    font-size: 20px;
    color: white;
    margin-bottom: 10px;
}

.select_box{
    background: #455060 !important;
    height: 40px;
    border-radius: 20px;
    display: block;
    width: 100%;
    color: #fff;
    border: 0px;
    padding: 0 20px;
}

.text_box, .text_box:focus{
    background: #455060;
    height: 40px;
    border-radius: 20px;
    display: block;
    width: 100%;
    color: #fff;
    border: 0px;
    padding: 0 20px;
    outline: none;
    box-shadow: none;
}
.badge_modal .modal-content{
    background: #E3EBF3;
}
.img_up_wrapper{
    margin-top: 40px;
}
.img_up_wrapper .btn, .img_up_wrapper{
    display: block;
    width: 100%;
}

.up_submit_but .upload_submit{
    background: #01C1C1;
    border-radius: 30px;
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    border: 0px;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    display: block;
    cursor: pointer;
    width: 100%;
}

.up_submit_but .upload_submit:focus{
    outline: 0;
    box-shadow: none;
}

@media screen and (max-width:575px){
    .addnew_badge_body{
        padding: 0 20px;
    }

    .two_image_upload{
        flex-wrap: wrap;
    }

    .left_image_upload.up_img{
        width: 100%;
        display: block;
        margin-bottom: 30px;
    }
    .left_image_upload h2{
        font-size: 20px;
    }
}
@media screen and (max-width:480px){
    .badges_list_heading{
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
}