.setting_left_inner_partts h2 { color:#fff}

.levelsetting {
    background: #5B687A;
    border-radius: 15px;
    padding: 30px 20px;
}

.mb_2{
    margin-bottom: 20px !important;
    padding-bottom: 5px !important;
}

.text_box.form-select { 
    background-color: #455060;
    background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
}

.seperate_section {padding:20px; background: #505C6D;
    margin-left: -20px;
    margin-right: -20px;
}

.main_head {color:#fff; font-size:16px; font-weight: 400;}
.levelsetting .form-label {color:rgb(190, 190, 190); font-size: 14px;}

.parsentage_box, .parsentage_box:focus {   
    background-image: url(../../assets/images/purcentage_icon.png);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 12px 11px;
    padding-right: 30px;
}

.levelsetting h5 {font-size: 18px; color:#fff}

.my_button {background-color: #01C1C1; color:#fff; border: none; border-radius: 5px; width:159px; height:46px; font-size: 18px;}
.my_button:hover, .my_button:focus, .my_button:active { background-color:#01aaaa !important ; color:#fff; border: none !important;}

.levelsetting hr {border-color: #fff;}


.percentageInput::-webkit-outer-spin-button,
.percentageInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.percentageInput[type=number] {
  -moz-appearance: textfield;
}

.errorMessage{
    color: #f15959;
    text-transform: capitalize;
    font-size: initial;
    
}

