.add_new_promo{
    background: #01C1C1;
    border-radius: 30px;
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    border: 0px;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    display: inline-block;
}
  
.link_style{
    color: white;
}
.link_style:hover{
    color: white;
}
.action_adjust{
    margin-left: 1rem;
}
.promo_restriction{
    margin-top: 1rem;
    margin-left: 0.5rem;
}