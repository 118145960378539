.payout_status_success h5 span {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #2ED47A;
    border: 3px solid white;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.manEyeStyle.view {
    margin-left: 0.6rem;
}

.amount-color {
    color: #00FFFF;
}

.man_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    margin-left: 5px;
    text-align: justify;
    width: 100%
        /* max-width: 70%; width: 100%; */
    ;
}

.state_parttsss.amount-color h2 {
    color: #00FFFF;
}

.payout_status_success h5 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 18px;
    color: #fff;
}

.payout_status_warning h5 span {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #FFF203;
    border: 3px solid white;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.payout_status_warning h5 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 18px;
    color: #fff;
}

.payout_status_danger h5 span {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #f15959;
    border: 3px solid white;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.payout_status_danger h5 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 18px;
    color: #fff;
}

.managment_section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 26px;
    margin: 0;
    padding: 0;
    gap: 20px;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-bg);
    pointer-events: auto;
    background-color: rgb(61 69 85);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
}

.modal-content .form-control,
.modal-content .form-select {
    background-color: transparent;
    color: white;
}

.modal-content .form-select option {
    background: rgb(61 69 85);
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}