.dash_partts_inner .title {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.reporters_profile {
  display: flex;
  flex-direction: row;
  padding: 2rem;
  border-radius: 1rem;
  color: #fff;
  background: #545f70;
  gap: 30px;
}

.report_profile_image img {
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  border: 2px solid #ffffff33;
}

.reporter_info {
  flex: 1 1 auto;
}

.reporter_name {
  margin-bottom: 0px;
  color: white;
  font-size: 1.8rem;
  font-weight: 600;
}
.info_list {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.info_list_item {
  margin-bottom: 0px;
}
.info_list_item dt {
  font-size: 13px;
  color: #dedede;
}
.info_list_item dd {
  font-size: 18px;
  font-weight: 600;
}

.credit_color {
  color: #4caf50;
}
.debit_color {
  color: #ff9800;
}
.withdraw_color {
  color: #f44336;
}
.profit {
  color: #4caf50;
}
.loss {
  color: #f44336;
}
.info_block.report_block {
  justify-content: flex-start;
}
.item_info.reporting {
  flex: 0 0 calc(33% - 20px);
  max-width: calc(33% - 20px);
  margin-bottom: 10px;
  padding: 0.8 rem 1rem;
  margin-left: 10px;
  margin-right: 10px;
}

.item_info.reporting .info_value {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 10px;
}
.item_info.reporting .info_title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 20px;
}

.reporter_division_image {
  width: 40px;
  height: 40px;
  margin-right: 0px;
  border-radius: 50%;
}

.reporter_divsor_name {
  color: #ffffff;
  margin-left: 5px;
}

.reporter_username {
  color: #ffffff;
}
.device_counte {
  background-color: #515b6c;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}
.device_counte p {
  font-weight: 400;
  font-size: 20px;
  color: white;
  margin-bottom: 0px;
  margin-top: 0px;
}
.device_counte .counter {
  justify-content: space-around;
}

.alertReportMsg{
    background-color: rgba(165, 51, 20, 0.7);
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    color: black;
}

.item_info_balancereport{
  color: #fff;
}
.balance-r-value{
font-size: 42px;
}
.balance-r-title{
  font-size: 25px;
}

.item_info.reportingKeyMatrics {
  flex-grow: 0;
  flex-shrink: 0;
  flex: 0 0 calc(50% - 10px);
  margin-bottom: 10px;
  padding: 1rem;
  /* margin-left: 10px; */
  /* margin-right: 10px; */
  max-width: calc(50% - 10px);
  text-align: center;
  cursor: unset;
}
.gapReport{
  gap: 10px;
}

.item_info.reportingKeyMatrics .info_value {
  font-size: 2.4rem;
  font-weight: 600;
}
.item_info.reportingKeyMatrics .info_title {
  font-size: 1.4rem;
  font-weight: 600;
}
.info_desc{
  font-size: 13px;
  font-style: italic;
  color: #d0d4d9;
}


@media screen and (max-width: 520px) {
  .balance-r-value{
    font-size: 28px;
    }
    .balance-r-title{
      font-size: 15px;
    }
}

