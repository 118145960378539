.active_inner_list.active_inner_height{
    min-height: 150px;
}
.man_ph.text_center{
    margin-left: 1.5rem;
}

.active_inner_list.active_inner_height h2{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #c2cddd;
    margin-bottom: 10px;
}
.active_inner_list.active_inner_height.inner_height_first{
    padding: 40px 30px;
}
.man_ph{
    color: white !important;
}
.man_email{
    color: white !important;
}
.active_text.active_label{
    font-weight: 400;
    font-size: 30px;
    line-height: 59px;
    color: #2bfafa;
    margin-bottom: 0px;
}

.active_flex_box{
    display: flex;
    justify-content: space-between;
}

.active_heading_parttts{
    max-width: 60%;
    width: 100%;
}

.active_image_parttts{
    max-width: 40%;
    width: 100%;
}

.buy_nftts{
    color: #2bfafa;
    font-size: 13px;
}

/* .nft_title{
    padding-left: 40px;
} */

@media screen and (max-width:991px){
    .active_flex_box{
        flex-wrap: wrap;
    }
    .active_text.active_label{
        font-size: 20px;
    }
}

@media screen and (max-width:767px){
    .active_inner_list.active_inner_height.inner_height_first{
        margin-bottom: 30px;
    }

    .active_inner_list.active_inner_height{
        height: auto;
    }
}