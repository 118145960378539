.nft_collect_inner_body{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -10px;

}
.nft_collect_body{
    margin-top: 50px;
}
.nft_collct_inner_body_part{
    max-width: calc(100%/4);
    width: 100%;
    padding: 0 10px;
}

.nft_collect_inner_partts{
    background: #545F70;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    height: 100%;
    margin-bottom: 40px;
    /* padding: 10px; */
}
.nft_collect_top_img{
    width: 100%;
    height: 140px;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
}
.nft_collect_top_img img{
    max-width: 100%;
    max-height: 140px;
    object-fit: cover;
}

.bottom_details_nft_collect.nft_collect_bottom_details{
    padding: 20px 10px;
    display: block;
    min-height: auto;
}
.nft_collect_bottom_details .nft_collect_bottom_detailspart{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.nft_collect_details_left{
    max-width: 100%;
    width: 100%;
}
.bottom_details_nft_collect.nft_collect_bottom_details .nft_collect_details_left{
    min-height: auto;
}
.nft_collect_details_right{
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.nft_creatorri{
    margin: 22px 0px 20px 0;
}
.nft_collect_details_left h2{
    font-size: 20px;
    color: #fff;
    margin-bottom: 10px;
}

.nft_collect_details_left h4, .nft_collect_details_right h4{
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
}
.nft_collect_details_left h5, .nft_creatorri h5{
    font-style: italic;
    color: #fff;
    font-size: 17px;
}
.nft_creatorri h5{
    text-align: center;
}
.view_but{
    margin-bottom: 10px;
    text-align: center;
}
.view_but a{
    border: 2px solid #fff;
    border-radius: 6px;
    color: #fff;
    height: 28px;
    line-height: 22px;
    padding: 0 7px;
    display: inline-block;
}

.nft_collect_body ._loading_overlay_wrapper{
    height: 100%;
    position: relative;
    z-index: 9;
}

@media screen and (max-width:1199px){
    .nft_collct_inner_body_part{
        max-width: calc(100%/3);
    }
    .nft_collect_details_left h5{
        font-size: 13px;
    }
}


@media screen and (max-width:991px){
.nft_collct_inner_body_part {
    max-width: calc(100%/2);
}
}

@media screen and (max-width:575px){
    .nft_collct_inner_body_part {
        max-width: calc(100%/1);
    }
}