:root {
    --color1: #DCAF51;
    --color2: #D69052;
    --color3: #D77663;
    --color4: #AA5555;
    --color5: #CA6477;
    --color6: #974154;
    --color7: #66577B;
    --color8: #005D6C;
    --color9: #537B7A;
    --color10: #629BA4;
    --color11: #75846D;
    --color12: #6D945D;

}



.timelineUL {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
    width: 665px;
}

.timelineUL li {
    margin-bottom: 30px;
}

.timelineUL li:nth-child(even) {
    align-self: flex-start;
}

.timelineUL li:nth-child(odd) {
    align-self: flex-end;
}

.timelineUL li:nth-child(even) .timeline_item {
    justify-content: end;
}

.timelineUL li:nth-child(even) .timeline_item .timeline_content {text-align: right;}
.timelineUL li:nth-child(odd) .timeline_item {
    justify-content: start;
}

.timeline_item {
    width: 400px;
    height: 100px;
    border-radius: 100px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    /* background: #9b7a01; */
    gap: 15px;
    padding: 5px 10px;
    position: relative;
}

.timeline_item .number {
    flex: 0 0 90px;
    width: 90px;
    height: 90px;
    border-radius: 50px;
    background: #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 13px rgb(172, 172, 172) inset;
}

.timeline_item .number span {
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 70px;
    background: #ededed;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 3px 5px rgba(129, 129, 129, 0.3), -2px -2px 3px rgb(255, 255, 255);
    font-size: 30px;
    font-weight: 800;
    color: #9b7a01
}
.timeline_item .number span img{
   border-radius: 50%;
}

.timeline_item .timeline_content {
    /* overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; */
    /* number of lines to show */
    /* line-clamp: 3;
    -webkit-box-orient: vertical; */
    color: #fff;
    font-size: 18px;
    padding-right: 15px;
    line-height: 22px;
}
.timeline_item .timeline_content h4 { font-weight: 700;}
.timeline_item .timeline_content h4 p {font-weight: 300;}
.timeline_item .timeline_content p {font-weight: 900; font-size: 18px;}
.timeline_item .timeline_content h4, .timeline_item .timeline_content p {margin-bottom: 0;}

.timelineUL li:nth-child(even) .timeline_item .number {
    order: 1;
}

.timelineUL li:nth-child(even) .timeline_item .timeline_content {
    padding-left: 15px;
}

.timelineUL li:nth-child(1) .timeline_item {
    background: var(--color1);
}

.timelineUL li:nth-child(2) .timeline_item {
    background: var(--color2);
}

.timelineUL li:nth-child(3) .timeline_item {
    background: var(--color3);
}

.timelineUL li:nth-child(4) .timeline_item {
    background: var(--color4);
}

.timelineUL li:nth-child(5) .timeline_item {
    background: var(--color5);
}

.timelineUL li:nth-child(6) .timeline_item {
    background: var(--color6);
}

.timelineUL li:nth-child(7) .timeline_item {
    background: var(--color7);
}

.timelineUL li:nth-child(8) .timeline_item {
    background: var(--color8);
}

.timelineUL li:nth-child(9) .timeline_item {
    background: var(--color9);
}

.timelineUL li:nth-child(10) .timeline_item {
    background: var(--color10);
}

.timelineUL li:nth-child(11) .timeline_item {
    background: var(--color11);
}

.timelineUL li:nth-child(12) .timeline_item {
    background: var(--color12);
}

.timelineUL li:nth-child(1) .timeline_item span {
    color: var(--color1);
}

.timelineUL li:nth-child(2) .timeline_item span {
    color: var(--color2);
}

.timelineUL li:nth-child(3) .timeline_item span {
    color: var(--color3);
}

.timelineUL li:nth-child(4) .timeline_item span {
    color: var(--color4);
}

.timelineUL li:nth-child(5) .timeline_item span {
    color: var(--color5);
}

.timelineUL li:nth-child(6) .timeline_item span {
    color: var(--color6);
}

.timelineUL li:nth-child(7) .timeline_item span {
    color: var(--color7);
}

.timelineUL li:nth-child(8) .timeline_item span {
    color: var(--color8);
}

.timelineUL li:nth-child(9) .timeline_item span {
    color: var(--color9);
}

.timelineUL li:nth-child(10) .timeline_item span {
    color: var(--color10);
}

.timelineUL li:nth-child(11) .timeline_item span {
    color: var(--color11);
}

.timelineUL li:nth-child(12) .timeline_item span {
    color: var(--color12);
}


.timelineUL li:nth-child(odd) .timeline_item::after {
    content: "";
    width: 107px;
    height: 140px;
    position: absolute;
    right: 100%;
    bottom: 100%;
    transform: translate(80%, 86%);

    background-color: transparent;
    border-top-left-radius: 110px;
    border-bottom-left-radius: 110px;
    border: 10px solid #283342;
    border-right: 0;
}

.timelineUL li:nth-child(even) .timeline_item::after {
    content: "";
    width: 107px;
    height: 140px;
    position: absolute;
    left: 100%;
    bottom: 100%;
    transform: translate(-80%, 86%);

    background-color: transparent;
    border-top-right-radius: 110px;
    border-bottom-right-radius: 110px;
    border: 10px solid #283342;
    border-left: 0;
}

.time_line {display: flex; align-items: center; justify-content: center;}