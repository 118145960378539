.logout_inner_page{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logout_text h2{
    font-weight: 400;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    color: #FFFFFF;
}

.logout_buttons{
    text-align: center;
    margin-top: 20px;
}

.logout_buttons .yes_but{
    background: #01C1C1;
    border-radius: 50px;
    height: 50px;
    line-height: 46px;
    padding: 0 45px;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #fff;
    margin: 0 5px;
    text-transform: uppercase;
    border: 0px;
}
.logout_buttons .no_but{
    background: #323C49;
    border-radius: 50px;
    height: 50px;
    line-height: 46px;
    padding: 0 50px;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #fff;
    margin: 0 5px;
    text-transform: uppercase;
    border: 0px;
}

.logout_buttons button:hover, .logout_buttons button:focus{
    outline: 0px;
    border: none;
}